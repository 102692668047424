import React, {useEffect, useState} from 'react';
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import NoInternet from "../no-internet/NoInternet";

const Layout = ({children}) => {

    const [networkStatus, setNetworkStatus] = useState(() => {
        if (navigator.onLine) {
            return true;
        } else {
            return false;
        }
    });

    useEffect(() => {
        window.ononline = (e) => {
            setNetworkStatus(true);
        };
        window.onoffline = (e) => {
            setNetworkStatus(false);
        };
    }, [networkStatus]);

    return (
        <div className="Layout mt-4 pt-5">
            <Navbar/>
            {
                networkStatus ?

                    children :

                    <NoInternet/>
            }
            <Footer/>
        </div>
    );
};

export default Layout;