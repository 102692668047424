import {
    BTN_LOADING_FALSE,
    BTN_LOADING_TRUE,
    GET_CATEGORIES,
    LOADING_FALSE,
    LOADING_TRUE,
    REMOVE_DELETE_CATEGORY,
    REMOVE_UPDATE_CATEGORY,
    SET_DELETE_CATEGORY,
    SET_UPDATE_CATEGORY
} from "../actionTypes/actionsTypes";

const initialState = {

    categories: [],

    delete_category: null,
    update_category: null,

    loading: false,
    btn_loading: false,
};

export const categoriesReducer = (state = initialState, action) => {

    const {type, payload} = action;

    switch (type) {

        case LOADING_TRUE:

            return {
                ...state,
                loading: true
            };

        case LOADING_FALSE:

            return {
                ...state,
                loading: false
            };

        case BTN_LOADING_TRUE:

            return {
                ...state,
                btn_loading: true
            };

        case BTN_LOADING_FALSE:

            return {
                ...state,
                btn_loading: false
            };

        case GET_CATEGORIES:

            return {
                ...state,
                categories: payload.categories,
                loading: false
            };

        case SET_DELETE_CATEGORY:

            return {
                ...state,
                delete_category: payload
            };

        case REMOVE_DELETE_CATEGORY:

            return {
                ...state,
                delete_category: null
            };

        case SET_UPDATE_CATEGORY:

            return {
                ...state,
                update_category: payload
            };

        case REMOVE_UPDATE_CATEGORY:

            return {
                ...state,
                update_category: null
            };

        default:
            return state;
    }
};
