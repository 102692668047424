import {
    BTN_LOADING_FALSE,
    BTN_LOADING_TRUE, GET_CATEGORIES_DATA,
    GET_PRODUCTS,
    LOADING_FALSE,
    LOADING_TRUE,
    SET_DELETE_PRODUCT,
    SET_UPDATE_PRODUCT
} from "../actionTypes/actionsTypes";
import axios from "axios";
import {toast} from "react-toastify";
import {PATH_NAME, TOKEN_NAME} from "../../tools/constant";

export const getCategoriesData = () => async (dispatch) => {

    dispatch({
        type: LOADING_TRUE
    });

    try {

        const res = await axios.get(PATH_NAME + `/api/category/`);

        dispatch({
            type: GET_CATEGORIES_DATA,
            payload: {
                categories: res.data,
            }
        });

        dispatch({
            type: LOADING_FALSE,
        });

    } catch (err) {
        dispatch({
            type: LOADING_FALSE,
        });
    }

};

export const getProducts = (search, searchCategory) => async (dispatch) => {

    dispatch({
        type: LOADING_TRUE
    });

    try {

        const res = await axios.get(PATH_NAME + `/api/product/?search=${search}&category=${searchCategory}`);

        dispatch({
            type: GET_PRODUCTS,
            payload: {
                products: res.data,
            }
        });

        dispatch({
            type: LOADING_FALSE,
        });

    } catch (err) {
        dispatch({
            type: LOADING_FALSE,
        });
    }

};

export const addProduct = (data, handleClose, getProductsRequest) => async (dispatch) => {

    dispatch({
        type: BTN_LOADING_TRUE
    });

    try {

        const accessToken = JSON.parse(localStorage.getItem(TOKEN_NAME));

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const res = await axios.post(`${PATH_NAME}/api/product/`, data, config);

        handleClose();

        getProductsRequest();

        toast.success(`Mahsulot muvaffaqiyatli yaratildi !!!`);

    } catch (err) {

        dispatch({
            type: BTN_LOADING_FALSE,
        });

        toast.error(`Ma'lumot yaratilmadi !!!`);
    } finally {
        dispatch({
            type: BTN_LOADING_FALSE,
        });
    }

};

export const setDeleteProduct = (product) => async (dispatch) => {

    try {

        dispatch({
            type: SET_DELETE_PRODUCT,
            payload: product
        });

    } catch (err) {
        console.log(err)
    }
};

export const setUpdateProduct = (product) => async (dispatch) => {

    try {

        dispatch({
            type: SET_UPDATE_PRODUCT,
            payload: product
        });

    } catch (err) {
        console.log(err)
    }
};

export const deleteProduct = (productID, handleClose, getProductsRequest) => async (dispatch) => {

    dispatch({
        type: BTN_LOADING_TRUE
    });

    try {

        const accessToken = JSON.parse(localStorage.getItem(TOKEN_NAME));

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const res = await axios.delete(`${PATH_NAME}/api/product/${productID}/`, config);

        handleClose();

        toast.success(`Mahsulot muvaffaqiyatli o'chirildi !!!`);

        getProductsRequest();

    } catch (err) {

        dispatch({
            type: BTN_LOADING_FALSE,
        });

        toast.error(`Ma'lumot o'chirilmadi !!!`);
    } finally {
        dispatch({
            type: BTN_LOADING_FALSE,
        });
    }

};

export const updateProduct = (productID, data, handleClose, getProductsRequest) => async (dispatch) => {

    dispatch({
        type: BTN_LOADING_TRUE
    });

    try {

        const accessToken = JSON.parse(localStorage.getItem(TOKEN_NAME));

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const res = await axios.patch(`${PATH_NAME}/api/product/${productID}/`, data, config);

        handleClose();

        toast.success(`Mahsulot muvaffaqiyatli tahrirlandi !!!`);

        getProductsRequest();

    } catch (err) {

        dispatch({
            type: BTN_LOADING_FALSE,
        });

        toast.error(`Ma'lumot o'zgartirilmadi !!!`);
    } finally {
        dispatch({
            type: BTN_LOADING_FALSE,
        });
    }

};
