import {combineReducers} from "redux";
import {tradesReducer} from "./tradesReducer";
import {authReducer} from "./authReducer";
import {categoriesReducer} from "./categoriesReducer";
import {productsReducer} from "./productsReducer";
import {statisticReducer} from "./statisticReducer";

export const rootReducer = combineReducers({
    auth: authReducer,
    trades: tradesReducer,
    categories: categoriesReducer,
    products: productsReducer,
    statistic: statisticReducer,
});
