import React, {useEffect, useState} from 'react';
import Layout from "../../components/layout/Layout";
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {getTrade, setDeleteTradePayment, setUpdateTradePayment} from "../../redux/actions/tradesActions";
import SiteLoading from "../../components/site-loading/SiteLoading";
import {BsCartFill, BsFillPersonLinesFill} from "react-icons/bs";
import {AiFillPhone, AiOutlinePlus, AiOutlineQrcode} from "react-icons/ai";
import {BiEdit} from "react-icons/bi";
import {RiDeleteBin2Line, RiMoneyDollarBoxFill, RiMoneyDollarBoxLine, RiPassportFill} from "react-icons/ri";
import {MdDateRange, MdPriceCheck, MdOutlineArrowBack} from 'react-icons/md';
import {GiSandsOfTime, GiReceiveMoney, GiPayMoney} from 'react-icons/gi';
import {MdSignalWifiStatusbar3Bar} from 'react-icons/md';
import {BiMoneyWithdraw} from 'react-icons/bi';
import {CgCalendarDates} from 'react-icons/cg';
import {PiNotePencil} from 'react-icons/pi';
import {FaAmazonPay} from 'react-icons/fa';
import TableLoading from "../../components/table-loading/TableLoading";
import TableNoData from "../../components/table-no-data/TableNoData";
import AddTradePayment from "../../components/admin/AddTradePayment";
import DeleteTradePayment from "../../components/admin/DeleteTradePayment";
import UpdateTradePayment from "../../components/admin/UpdateTradePayment";
import {useHistory} from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';

const AdminTrade = () => {

    const {id} = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const {trade, loading, tradePayments} = useSelector(state => state.trades);

    useEffect(() => {
        dispatch(getTrade(id));
    }, []);

    const getTradeFunc = () =>{
        dispatch(getTrade(id));
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const toggle = () => setShow(!show);

    const setDeletePaymentFunc = (payment) => {
        dispatch(setDeleteTradePayment(payment));
    };

    const setUpdatePaymentFunc = (payment) => {
        dispatch(setUpdateTradePayment(payment));
    };

    if (loading) {
        return (
            <SiteLoading/>
        )
    }

    return (
        <Layout>
            <div className="Groups min-vh-100 mb-5 mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card border-2 border-dark mb-5">
                                <div className="card-header border-dark bg-dark text-white">

                                    <div className="d-flex align-items-center mb-2">
                                        <div><BsFillPersonLinesFill
                                            style={{fontSize: "18px", marginRight: '5px'}}/> Mijoz:
                                        </div>
                                        <div className="ms-1 fw-bold">{trade?.client_name}</div>
                                    </div>

                                    <div className="d-flex align-items-center mb-2">
                                        <div><AiFillPhone style={{fontSize: "18px", marginRight: '5px'}}/> Telefon:
                                        </div>
                                        <div className="ms-1 fw-bold">{trade?.client_num}</div>
                                    </div>

                                    <div className="d-flex align-items-center mb-2">
                                        <div><RiPassportFill style={{fontSize: "18px", marginRight: '5px'}}/> Passport:
                                        </div>
                                        <div className="ms-1 fw-bold">{trade?.client_passport}</div>
                                    </div>

                                </div>
                                <div className="card-body">
                                    <div className="d-flex align-items-center mb-2">
                                        <div><BsCartFill style={{fontSize: "18px", marginRight: '5px'}}/> Mahsulot:
                                        </div>
                                        <div className="ms-1 fw-bold">{trade?.product?.name}</div>
                                    </div>

                                    <div className="d-flex align-items-center mb-2">
                                        <div><AiOutlineQrcode style={{fontSize: "18px", marginRight: '5px'}}/> IMEI:
                                        </div>
                                        <div className="ms-1 fw-bold">{trade?.imeika}</div>
                                    </div>

                                    <div className="d-flex align-items-center mb-2">
                                        <div><RiMoneyDollarBoxLine
                                            style={{fontSize: "18px", marginRight: '5px'}}/> Narxi:
                                        </div>
                                        <div className="ms-1 fw-bold">
                                            <CurrencyFormat value={trade?.price}
                                                            displayType={'text'}
                                                            thousandSeparator={" "}
                                                            suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center mb-2">
                                        <div><BiMoneyWithdraw
                                            style={{fontSize: "18px", marginRight: '5px'}}/> Qo'shimcha to'lov:
                                        </div>
                                        <div className="ms-1 fw-bold">
                                            <CurrencyFormat value={trade?.surcharge}
                                                            displayType={'text'}
                                                            thousandSeparator={" "}
                                                            suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center mb-2">
                                        <div><RiMoneyDollarBoxFill style={{fontSize: "18px", marginRight: '5px'}}/>Ustama
                                            bilan narxi:
                                        </div>
                                        <div className="ms-1 fw-bold">
                                            <CurrencyFormat value={trade?.total}
                                                            displayType={'text'}
                                                            thousandSeparator={" "}
                                                            suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center mb-2">
                                        <div><MdPriceCheck style={{fontSize: "18px", marginRight: '5px'}}/> Oldindan
                                            to'lov:
                                        </div>
                                        <div className="ms-1 fw-bold">
                                            <CurrencyFormat value={trade?.ad_payment}
                                                            displayType={'text'}
                                                            thousandSeparator={" "}
                                                            suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center mb-2">
                                        <div><FaAmazonPay style={{fontSize: "18px", marginRight: '5px'}}/> Oylik to'lov:
                                        </div>
                                        <div className="ms-1 fw-bold">
                                            <CurrencyFormat value={trade?.monthly_pay?.toFixed(2)}
                                                            displayType={'text'}
                                                            thousandSeparator={" "}
                                                            suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center mb-2">
                                        <div><MdDateRange style={{fontSize: "18px", marginRight: '5px'}}/> Boshlangan
                                            sanasi:
                                        </div>
                                        <div className="ms-1 fw-bold">{trade?.start}</div>
                                    </div>

                                    <div className="d-flex align-items-center mb-2">
                                        <div><GiSandsOfTime style={{fontSize: "18px", marginRight: '5px'}}/> Muddat:
                                        </div>
                                        <div className="ms-1 fw-bold">{trade?.dedline} oy</div>
                                    </div>

                                    <div className="d-flex align-items-center mb-2">
                                        <div><CgCalendarDates
                                            style={{fontSize: "18px", marginRight: '5px'}}/> Yakunlanish sanasi:
                                        </div>
                                        <div className="ms-1 fw-bold">{trade?.end}</div>
                                    </div>

                                    <hr/>

                                    <div className="mb-2">
                                        <div><PiNotePencil
                                            style={{fontSize: "18px", marginRight: '5px'}}/> <span className="fw-bold">Izoh:</span>
                                        </div>
                                        <div className="mt-2"><i>{trade?.desc}</i></div>
                                    </div>

                                    <hr/>

                                    <div className="d-flex align-items-center mb-2">
                                        <div><GiReceiveMoney style={{fontSize: "18px", marginRight: '5px'}}/> To'langan
                                            summa:
                                        </div>
                                        <div className="ms-1 fw-bold text-success">
                                            <CurrencyFormat value={trade?.common_payment?.toFixed(2)}
                                                            displayType={'text'}
                                                            thousandSeparator={" "}
                                                            suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center mb-2">
                                        <div><GiPayMoney style={{fontSize: "18px", marginRight: '5px'}}/> Qolgan summa:
                                        </div>
                                        <div className="ms-1 fw-bold text-danger">
                                            <CurrencyFormat value={trade?.debt_balance?.toFixed(2)}
                                                            displayType={'text'}
                                                            thousandSeparator={" "}
                                                            suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                        </div>
                                    </div>

                                    <div className="d-flex align-items-center mb-2">
                                        <div><MdDateRange style={{fontSize: "18px", marginRight: '5px'}}/> Keyingi to'lov sanasi:
                                        </div>
                                        {
                                            trade.status === "Shartnoma yakunlangan" ?

                                                <>

                                                </>:

                                                <>
                                                    <div className="ms-1 fw-bold">{trade?.next_pay}</div>
                                                </>

                                        }
                                    </div>

                                    <hr/>

                                    <div className="d-flex align-items-center mb-2">
                                        <div><MdSignalWifiStatusbar3Bar
                                            style={{fontSize: "18px", marginRight: '5px'}}/> Status:
                                        </div>
                                        <div className="ms-1 fw-bold">
                                            {trade?.status === "To'langan" ?
                                                <>
                                                    <div className={trade?.status === "To'langan" ? "fw-bold text-success" :  trade?.status === "Qarzdorlik" ?   "fw-bold text-danger": "fw-bold text-warning"}>
                                                        {trade?.status}
                                                    </div>
                                                </> :

                                                trade.status === "Qarzdorlik" ?

                                                    <>
                                                        <div className={trade?.status === "To'langan" ? "fw-bold text-success" :  trade?.status === "Qarzdorlik" ?   "fw-bold text-danger": "fw-bold text-warning"}>
                                                            {trade?.status}
                                                        </div>
                                                    </>:

                                                    <>
                                                        <div className={trade?.status === "To'langan" ? "fw-bold text-success" :  trade?.status === "Qarzdorlik" ?   "fw-bold text-danger": "fw-bold text-warning"}>
                                                            {trade?.status}
                                                        </div>
                                                    </>

                                            }
                                        </div>
                                    </div>

                                    <hr/>

                                    {trade?.status === "To'langan" ?

                                        <>
                                            <i>Keyingi to'lovga <b>{trade?.pay_day}</b> kun qoldi.</i>
                                        </> :

                                        trade.status === "Qarzdorlik" ?

                                            <>
                                                <i>To'lov sanasidan <b>{trade?.pay_day}</b> kun o'tib ketdi.</i>
                                            </>:

                                            <>
                                                <i>Barcha to'lovlar to'langan.</i>
                                            </>

                                    }


                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h2 className="text-dark fw-bold text-center">To'lovlar</h2>
                                        <div className="d-flex align-items-center">
                                            {
                                                trade?.debt_balance >0 && (
                                                    <button onClick={() => handleShow()} className="btn btn-success fw-bold">
                                                        To'lov
                                                        <AiOutlinePlus className="ms-2"/>
                                                    </button>
                                                )
                                            }
                                            <button onClick={() => history.goBack()}
                                                    className="btn ms-2 btn-dark fw-bold">
                                                Orqaga
                                                <MdOutlineArrowBack className="ms-2"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row my-5">
                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        <table className="table table-bordered table-hover text-center">
                                            <thead>
                                            <tr>
                                                <th className="col-2">№</th>
                                                <th>Summa</th>
                                                <th>Sana</th>
                                                <th>Keyingi to'lov sanasi</th>
                                                <th>Izoh</th>
                                                <th className="col-2">Sozlamalar</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                loading ?
                                                    <tr>
                                                        <td colSpan={10}>
                                                            <TableLoading/>
                                                        </td>
                                                    </tr> :

                                                    tradePayments?.length > 0 ? tradePayments?.map((payment, index) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    {index + 1}
                                                                </td>
                                                                <td>
                                                                    <CurrencyFormat value={payment?.summa?.toFixed(2)}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={" "}
                                                                                    suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                                                </td>

                                                                <td>{payment?.date?.slice(0, 11)}</td>
                                                                <td>
                                                                    {
                                                                        payment?.pay_type === "Tugatish"
                                                                            ? "Shartnoma yakunlagan !!!"
                                                                            :  payment?.finish?.slice(0, 11)
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {payment?.desc}
                                                                </td>
                                                                <td className="justify-content-around d-flex align-items-center">
                                                                    <button onClick={() => setUpdatePaymentFunc(payment)}
                                                                            className="btn btn-warning btn-sm">
                                                                        <BiEdit/>
                                                                    </button>
                                                                    <button onClick={() => setDeletePaymentFunc(payment)}
                                                                            className="btn btn-danger btn-sm">
                                                                        <RiDeleteBin2Line/>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )) :

                                                        <tr>
                                                            <th colSpan={10}>
                                                                <TableNoData text="To'lovlar topilmadi !!!"/>
                                                            </th>
                                                        </tr>
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <AddTradePayment handleClose={handleClose} show={show} toggle={toggle}/>

            <DeleteTradePayment/>

            <UpdateTradePayment/>


        </Layout>
    );
};

export default AdminTrade;