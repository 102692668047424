import React from 'react';
import {useSelector} from "react-redux";
import {Redirect, Route} from 'react-router-dom';

const PrivateRoute = ({component: Component, forUser: forUser, ...rest}) => {

    const {user} = useSelector(state => state.auth);

    return (
        <Route {...rest} render={(props) =>(
                user ? (
                    <Component {...props} />
                ) : (
                    <Redirect to='/login' />
                )
            )
        } />
    );
};

export default PrivateRoute;