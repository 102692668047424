import {
    GET_COURSE_AND_TEACHER_AND_ROOM_DATA, GET_COURSE_AND_TEACHER_DATA,
    GET_COURSE_STATISTIC,
    GET_GROUP_STATISTIC,
    GET_TEACHER_STATISTIC,
    LOADING_FALSE,
    LOADING_TRUE
} from "../actionTypes/actionsTypes";
import {PATH_NAME} from "../../tools/constant";
import axios from "axios";

export const getCourseStatistic = (searchStatus) => async (dispatch) => {

    dispatch({
        type: LOADING_TRUE
    });

    try {

        const res1 = await axios.get(`${PATH_NAME}/api/course/`);

        const res2 = await axios.get(`${PATH_NAME}/api/statistic-group/?status_filter=${searchStatus}`);

        let courses = res1.data;
        let groups = res2.data;

        let courseStatistic = [];

        courses?.forEach((course, index, arr) => {

            let course_group_data = groups?.filter(item => item?.course?.id === course?.id);

            if (course_group_data?.length > 0) {

                let courseName = course?.name;
                let studentCount = 0;

                course_group_data.forEach((group_data, index, array) => {
                    studentCount += group_data?.student_count;
                });

                courseStatistic.push({
                    course: courseName,
                    student_count: studentCount
                })

            }
        });

        dispatch({
            type: GET_COURSE_STATISTIC,
            payload: courseStatistic
        });

    } catch (err) {
        dispatch({
            type: LOADING_FALSE,
        });
    }
};

export const getTeacherStatistic = (searchCourse, searchStatus) => async (dispatch) => {

    dispatch({
        type: LOADING_TRUE
    });

    try {

        const res1 = await axios.get(`${PATH_NAME}/api/teacher/`);

        const res2 = await axios.get(`${PATH_NAME}/api/statistic-group/?course=${searchCourse}&status_filter=${searchStatus}`);

        let teachers = res1.data;
        let groups = res2.data;

        let teacherStatistic = [];

        teachers?.forEach((teacher, index, arr) => {

            let teacher_group_data = groups?.filter(item => item?.teacher?.id === teacher?.id);

            if (teacher_group_data?.length > 0) {

                let courseName = `${teacher?.first_name} ${teacher?.last_name}`;
                let studentCount = 0;

                teacher_group_data.forEach((group_data, index, array) => {
                    studentCount += group_data?.student_count;
                });

                teacherStatistic.push({
                    teacher: courseName,
                    student_count: studentCount
                })

            }
        });

        dispatch({
            type: GET_TEACHER_STATISTIC,
            payload: teacherStatistic
        });

    } catch (err) {
        dispatch({
            type: LOADING_FALSE,
        });
    }
};

export const getGroupStatistic = (searchCourse, searchTeacher, searchStatus) => async (dispatch) => {

    dispatch({
        type: LOADING_TRUE
    });

    try {

        const res = await axios.get(`${PATH_NAME}/api/statistic-group/?course=${searchCourse}&teacher=${searchTeacher}&status_filter=${searchStatus}`);

        let groups = res.data;

        let groupStatistic = [];

        for (let i = 0; i < groups.length; i++) {

            groupStatistic.push({
                group: groups[i].name,
                student_count: groups[i].student_count,
            });

        }

        dispatch({
            type: GET_GROUP_STATISTIC,
            payload: groupStatistic
        });

    } catch (err) {
        dispatch({
            type: LOADING_FALSE,
        });
    }
};

export const getCourseAndTeacherData = () => async (dispatch) => {

    try {

        const res1 = await axios.get(PATH_NAME + `/api/course/`);
        const res2 = await axios.get(PATH_NAME + `/api/teacher/`);

        dispatch({
            type: GET_COURSE_AND_TEACHER_DATA,
            payload: {
                courses: res1.data,
                teachers: res2.data
            }
        });

    } catch (err) {

    }

};