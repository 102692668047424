import {
    BTN_LOADING_FALSE,
    BTN_LOADING_TRUE, GET_CATEGORIES_DATA,
    GET_PRODUCTS,
    LOADING_FALSE,
    LOADING_TRUE,
    REMOVE_DELETE_PRODUCT,
    REMOVE_UPDATE_PRODUCT,
    SET_DELETE_PRODUCT,
    SET_UPDATE_PRODUCT
} from "../actionTypes/actionsTypes";

const initialState = {

    categories: [],

    products: [],

    delete_product: null,
    update_product: null,

    loading: false,
    btn_loading: false,
};

export const productsReducer = (state = initialState, action) => {

    const {type, payload} = action;

    switch (type) {

        case LOADING_TRUE:

            return {
                ...state,
                loading: true
            };

        case LOADING_FALSE:

            return {
                ...state,
                loading: false
            };

        case BTN_LOADING_TRUE:

            return {
                ...state,
                btn_loading: true
            };

        case BTN_LOADING_FALSE:

            return {
                ...state,
                btn_loading: false
            };

        case GET_CATEGORIES_DATA:

            return {
                ...state,
                categories: payload.categories,
                loading: false
            };

        case GET_PRODUCTS:

            return {
                ...state,
                products: payload.products,
                loading: false
            };

        case SET_DELETE_PRODUCT:

            return {
                ...state,
                delete_product: payload
            };

        case REMOVE_DELETE_PRODUCT:

            return {
                ...state,
                delete_product: null
            };

        case SET_UPDATE_PRODUCT:

            return {
                ...state,
                update_product: payload
            };

        case REMOVE_UPDATE_PRODUCT:

            return {
                ...state,
                update_product: null
            };

        default:
            return state;
    }
};
