import {
    BTN_LOADING_FALSE,
    BTN_LOADING_TRUE,
    GET_CATEGORY_AND_PRODUCT_DATA, GET_TRADE,
    GET_TRADES,
    LOADING_FALSE,
    LOADING_TRUE,
    REMOVE_DELETE_TRADE, REMOVE_DELETE_TRADE_PAYMENT,
    REMOVE_UPDATE_TRADE, REMOVE_UPDATE_TRADE_PAYMENT,
    SET_DELETE_TRADE, SET_DELETE_TRADE_PAYMENT,
    SET_UPDATE_TRADE, SET_UPDATE_TRADE_PAYMENT
} from "../actionTypes/actionsTypes";

const initialState = {

    categories: [],
    products: [],

    trades: [],
    numberOfPages: 1,

    trade: {},
    tradePayments: [],

    delete_trade: null,
    update_trade: null,

    delete_trade_payment: null,
    update_trade_payment: null,

    loading: false,
    btn_loading: false,
};

export const tradesReducer = (state = initialState, action) => {

    const {type, payload} = action;

    switch (type) {

        case LOADING_TRUE:

            return {
                ...state,
                loading: true
            };

        case LOADING_FALSE:

            return {
                ...state,
                loading: false
            };

        case BTN_LOADING_TRUE:

            return {
                ...state,
                btn_loading: true
            };

        case BTN_LOADING_FALSE:

            return {
                ...state,
                btn_loading: false
            };

        case GET_CATEGORY_AND_PRODUCT_DATA:

            return {
                ...state,
                categories: payload.categories,
                products: payload.products
            };


        case GET_TRADE:

            return {
                ...state,
                trade: payload.trade,
                tradePayments: payload.tradePayments,
                loading: false
            };

        case GET_TRADES:

            return {
                ...state,
                trades: payload.trades,
                numberOfPages: payload.numberOfPages,
                loading: false
            };

        case SET_DELETE_TRADE:

            return {
                ...state,
                delete_trade: payload
            };

        case SET_DELETE_TRADE_PAYMENT:

            return {
                ...state,
                delete_trade_payment: payload
            };

        case REMOVE_DELETE_TRADE:

            return {
                ...state,
                delete_trade: null
            };

        case REMOVE_DELETE_TRADE_PAYMENT:

            return {
                ...state,
                delete_trade_payment: null
            };

        case SET_UPDATE_TRADE:

            return {
                ...state,
                update_trade: payload
            };

        case SET_UPDATE_TRADE_PAYMENT:

            return {
                ...state,
                update_trade_payment: payload
            };

        case REMOVE_UPDATE_TRADE:

            return {
                ...state,
                update_trade: null
            };

        case REMOVE_UPDATE_TRADE_PAYMENT:

            return {
                ...state,
                update_trade_payment: null
            };

        default:
            return state;
    }
};
