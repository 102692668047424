import React from 'react';

const Footer = () => {
    return (
        <div className="Footer bg-dark py-3">
            <div className="container">
                <div className="text text-white text-center">
                    “SMART TIZIM” tomonidan barcha huquqlar himoyalangan. Copyright © 2023
                </div>
            </div>
        </div>
    );
};

export default Footer;