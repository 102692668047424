import React from 'react';

const TableNoData = ({text}) => {
    return (
        <div style={{height: "300px", width: "100%"}} className="d-flex align-items-center justify-content-center">
            <h4>{text}</h4>
        </div>
    );
};

export default TableNoData;