import React, {useEffect, useState} from 'react';
import {Column} from '@ant-design/plots';
import {useDispatch, useSelector} from "react-redux";
import {getCourseAndTeacherData, getTeacherStatistic} from "../../redux/actions/statisticActions";
import TableLoading from "../table-loading/TableLoading";
import TableNoData from "../table-no-data/TableNoData";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {ImSearch} from "react-icons/im";

const TeacherStatistic = () => {

    const dispatch = useDispatch();

    const [data, setData] = useState([]);

    const [searchCourse, setSearchCourse] = useState('');
    const [searchStatus, setSearchStatus] = useState('');
    const [search_condition, setSearchCondition] = useState(false);

    const handleSubmit = (event, data) => {
        setSearchCourse(data.course);
        setSearchStatus(data.status);
        setSearchCondition(!search_condition);
    };

    const paletteSemanticRed = '#F4664A';
    const brandColor = '#212529';

    const config = {
        data,
        xField: 'teacher',
        yField: 'student_count',
        seriesField: '',
        color: ({course}) => {
            /*if (course === "English") {
                return paletteSemanticRed;
            }*/
            return brandColor;
        },
        label: {
            content: (originData) => {
                const val = parseFloat(originData.student_count);
                return val;
            },
            offset: 10,
        },
        legend: false,
        xAxis: {
            label: {
                autoHide: true,
                autoRotate: false,
            },
        },
    };

    useEffect(()=>{
        dispatch(getCourseAndTeacherData());
    },[]);

    useEffect(()=>{
        dispatch(getTeacherStatistic(searchCourse, searchStatus));
    },[search_condition]);

    const {teacher_statistic, courses, loading} = useSelector(state => state.statistic);

    useEffect(() => {
        setData(teacher_statistic);
    }, [teacher_statistic]);

    return (
        <div className="Teacher-statistic">
            <div className="row">
                <div className="col-md-12">
                    <AvForm className="mb-5 mt-3" onValidSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-5">
                                <h4 className="fw-bold mb-3 mb-sm-0">Ustozlar</h4>
                            </div>
                            <div className="col-md-3">
                                <AvField
                                    value={searchCourse}
                                    type="select"
                                    name="course"
                                    className="form-select mb-2 mb-sm-0"
                                >
                                    <option value="" selected={true}>Kurs tanlang
                                    </option>
                                    {
                                        courses?.length !== 0 ? courses?.map((course, index) => (
                                                <option key={index}
                                                        value={course.id}>{course.name}</option>
                                            )) :

                                            <option value="" disabled={true}>Kurs
                                                mavjud emas
                                                !</option>
                                    }
                                </AvField>
                            </div>

                            <div className="col-md-2">
                                <AvField
                                    value={searchStatus}
                                    type="select"
                                    name="status"
                                    className="form-select mb-2 mb-sm-0"
                                >
                                    <option value="" selected={true}>Holatni tanlang</option>
                                    <option value="waiting">Waiting</option>
                                    <option value="active">Active</option>
                                    <option value="ended">Ended</option>
                                </AvField>
                            </div>

                            <div className="col-md-2">
                                <button className="btn btn-dark w-100">QIDIRISH <ImSearch className="ms-2"/>
                                </button>
                            </div>
                        </div>
                    </AvForm>
                </div>
                <div className="col-md-12">
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover text-center">
                            <thead>
                            <tr>
                                <th>№</th>
                                <th>Ism va familiya</th>
                                <th>Talabalar soni</th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                loading ?
                                    <tr>
                                        <td colSpan={3}>
                                            <TableLoading/>
                                        </td>
                                    </tr> :

                                    data?.length > 0 ? data?.map((item, index) => (
                                            <tr key={index}>
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>{item?.teacher}</td>
                                                <td>{item?.student_count}</td>
                                            </tr>
                                        )) :

                                        <tr>
                                            <th colSpan={3}>
                                                <TableNoData text="Ustoz topilmadi !!!"/>
                                            </th>
                                        </tr>
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="chart-box my-5">
                        <Column {...config} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeacherStatistic;