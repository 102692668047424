import React, {useRef, useState} from 'react';
import {Link, Redirect, useHistory} from "react-router-dom";
import {BsPersonCircle} from "react-icons/bs";
import {useDispatch, useSelector} from "react-redux";
import {AiFillEye, AiFillEyeInvisible} from "react-icons/ai";
import "./login.scss";
import {AuthLogin} from "../../redux/actions/authActions";

const Login = () => {

    const history = useHistory();

    const dispatch = useDispatch();

    const {user, loading, btn_loading} = useSelector(state => state.auth);

    const [show, setshow] = useState(false);
    const pass = useRef();

    const showPassword = () => {
        setshow(!show);
        pass.current.type = show ? 'password' : 'text';
    };

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        const user = {
            username: username,
            password: password
        };
        dispatch(AuthLogin(user, history));
    };

    /*if (user) {
        return <Redirect to="/admin/my-groups"/>
    }*/

    const myStyle = {
        backgroundColor: 'white'
    };

    return (
        <div className="Login" style={myStyle}>
            <div className="container vh-100 d-flex flex-column justify-content-center">
                <div className="row">
                    <div className="col-md-4 h-100 mx-auto">
                        <div className="d-flex w-100 flex-column align-items-center justify-content-center">
                            <h3 className="title font-weight-bold text-center">
                                Tizimga kirish
                            </h3>

                            <p className="commit text-center">
                                Username va parolingizni kiriting !!!
                            </p>

                            <div className="card border-2 border w-100 px-3 py-4">
                                <div className="card-body d-flex flex-column align-items-center justify-content-center">
                                    <div className="text-center">
                                        <BsPersonCircle
                                            style={{fontSize: "60px", color: "#212529"}}
                                        />
                                    </div>

                                    <form className="w-100 mt-4" onSubmit={handleSubmit}>

                                        <label className="form-label w-100">
                                            <p className="label-title fw-bold">
                                                Username
                                            </p>
                                            <input
                                                type="text"
                                                placeholder='Username ni kiriting'
                                                className="form-control"
                                                required={true}
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                            />
                                        </label>

                                        <label className="form-label w-100 mt-2">
                                            <p className="label-title fw-bold">
                                                Parol
                                            </p>
                                            <input
                                                ref={pass}
                                                type="password"
                                                required={true}
                                                placeholder='Parolni kiriting'
                                                className="form-control"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            />
                                            {
                                                !show ?

                                                    <AiFillEye style={{fontSize: "28px", color: "#212529"}} onClick={() => showPassword()} className="eye-icon"/> :

                                                    <AiFillEyeInvisible style={{fontSize: "28px", color: "#212529"}} onClick={() => showPassword()} className="eye-icon"/>
                                            }
                                        </label>

                                        <div className="text-center">
                                            <button
                                                type="submit"
                                                disabled={btn_loading}
                                                className="btn btn-dark mt-4 w-100 text-white fw-bold"
                                            >
                                                {
                                                    btn_loading &&
                                                    <div className="spinner-border  spinner-border-sm text-light me-2" role="status">
                                                    </div>
                                                }
                                                Tizimga kirish
                                            </button>
                                        </div>

                                        {/*<h6 className="d-flex align-items-center justify-content-center sign-up-box text-center mt-4">
                                            <div className="sign-up-title me-3">Ortga qaytmoqchimisiz ?</div>
                                            <Link to="/courses">Bosh sahifa</Link>
                                        </h6>*/}

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;