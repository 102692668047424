import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {REMOVE_UPDATE_TRADE_PAYMENT} from "../../redux/actionTypes/actionsTypes";
import {updateTradeForNextPay, updateTradePayment} from "../../redux/actions/tradesActions";
import CurrencyFormat from "react-currency-format";

const UpdateTradePayment = () => {

    const dispatch = useDispatch();

    const { update_trade_payment, trade, tradePayments, btn_loading} = useSelector(state => state.trades);

    const closeModal = () =>{
        dispatch({
            type: REMOVE_UPDATE_TRADE_PAYMENT
        })
    };

    const [pay_type, setPayType] = useState('');

    const onSubmit = (event, data) =>{

        if (pay_type === 'Tugatish') {
            data = {
                ...data,
                trade: trade?.id,
                summa: trade?.debt_balance,
                finish: trade?.next_pay
            };
        } else if (pay_type === "Maxsus to'lov") {
            data = {
                ...data,
                trade: trade?.id
            };
        } else {
            data = {
                ...data,
                trade: trade?.id,
                summa: trade?.monthly_pay,
            };
        }

        if (tradePayments.length == 1){
            dispatch(updateTradeForNextPay(trade?.id, {...trade, product: trade?.product?.id, next_pay: data.finish}));
        } else{
            dispatch(updateTradeForNextPay(trade?.id, {...trade, product: trade?.product?.id, next_pay: tradePayments[0]?.finish}));
        }

        dispatch(updateTradePayment(trade?.id, update_trade_payment?.id, data, closeModal))
    };

    let condition = false;
    if(update_trade_payment !== null)
        condition = true;

    useEffect(()=>{
        setPayType(update_trade_payment?.pay_type);
    },[update_trade_payment]);

    return (
        <div>

            <Modal isOpen={condition} toggle={closeModal}>
                <ModalHeader className="bg-warning text-white fw-bold px-4" toggle={closeModal}>Kategoriyani tahrirlash</ModalHeader>
                <ModalBody className="p-4">
                    <AvForm className="w-100" model={update_trade_payment} onValidSubmit={onSubmit}>

                        <AvField
                            type="select"
                            name="pay_type"
                            className="form-select mb-2"
                            label="To'lov turi"
                            value={pay_type}
                            onChange={(e) => setPayType(e.target.value)}
                            validate={{
                                required: {value: true, errorMessage: "To'lov turini tanlang !"}
                            }}
                        >
                            <option value="" selected={true} disabled={true}>To'lov turini tanlang</option>
                            <option value="Oylik to'lov">Oylik to'lov</option>
                            <option value="Maxsus to'lov">Maxsus to'lov</option>
                            <option value="Tugatish">Shartnomani yakunlash</option>
                        </AvField>

                        {
                            pay_type === "Maxsus to'lov" && (

                                <>
                                    <AvField
                                        type="number"
                                        label="Summa"
                                        name="summa"
                                        className="form-control mb-2"
                                        required
                                        validate={{
                                            required: {value: true, errorMessage: 'Summani kiriting !'}
                                        }}
                                    />

                                    <AvField
                                        type="date"
                                        label="To'lov sanasi"
                                        name="date"
                                        className="form-control mb-2"
                                        required
                                        validate={{
                                            required: {value: true, errorMessage: "To'lov sanasini kiriting !"}
                                        }}
                                    />

                                    <AvField
                                        type="date"
                                        label="Keyingi to'lov sanasi"
                                        name="finish"
                                        className="form-control mb-2"
                                        required
                                        validate={{
                                            required: {value: true, errorMessage: "Keyingi to'lov sanasini kiriting !"}
                                        }}
                                    />
                                </>

                            )
                        }

                        {
                            pay_type === "Oylik to'lov" && (

                                <>

                                    <div className="my-3">
                                        <label className="me-2">Summa:</label>
                                        <CurrencyFormat value={trade?.monthly_pay?.toFixed(2)}
                                                        displayType={'text'}
                                                        thousandSeparator={" "}
                                                        suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                    </div>

                                    <AvField
                                        type="date"
                                        label="To'lov sanasi"
                                        name="date"
                                        className="form-control mb-3"
                                        required
                                        validate={{
                                            required: {value: true, errorMessage: "To'lov sanasini kiriting !"}
                                        }}
                                    />

                                    <AvField
                                        type="date"
                                        label="Keyingi to'lov sanasi"
                                        name="finish"
                                        className="form-control mb-2"
                                        required
                                        validate={{
                                            required: {value: true, errorMessage: "Keyingi to'lov sanasini kiriting !"}
                                        }}
                                    />
                                </>

                            )
                        }

                        {
                            pay_type === 'Tugatish' && (

                                <>

                                    <div className="my-3">
                                        <label className="me-2">Summa:</label>
                                        <CurrencyFormat value={trade?.debt_balance}
                                                        displayType={'text'}
                                                        thousandSeparator={" "}
                                                        suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                    </div>

                                    <AvField
                                        type="date"
                                        label="To'lov sanasi"
                                        name="date"
                                        className="form-control mb-3"
                                        required
                                        validate={{
                                            required: {value: true, errorMessage: "To'lov sanasini kiriting !"}
                                        }}
                                    />
                                </>

                            )
                        }

                        <AvField
                            type="textarea"
                            label="Izoh"
                            name="desc"
                            className="form-control mb-2"
                        />

                        <div className="d-flex mt-4">
                            <button id="quit-btn" onClick={closeModal} type="button"
                                    className="w-50 btn btn-light fw-bold">Chiqish
                            </button>
                            <button id="submit-btn" type="submit" className={`w-50 ms-2 fw-bold text-white btn-dark btn`}
                                    disabled={btn_loading}>Ma'lumotlarni saqlash
                            </button>
                        </div>


                    </AvForm>
                </ModalBody>
            </Modal>

        </div>
    );
};

export default UpdateTradePayment;