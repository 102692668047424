import React, {Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {addProduct} from "../../redux/actions/productsActions";

const AddProduct = ({handleClose, show, toggle, getProductsRequest}) => {

    const dispatch = useDispatch();

    const {categories, btn_loading} = useSelector(state => state.products);

    const handleSubmit = (event, data) => {
        dispatch(addProduct(data, handleClose, getProductsRequest));
    };

    return (
        <Fragment>

            <Modal isOpen={show} toggle={toggle} className="add-modal">

                <ModalHeader className="bg-success fw-bold px-4 text-white" toggle={toggle}>Mahsulot qo'shish</ModalHeader>

                <ModalBody className="p-4">

                    <AvForm className="w-100" onValidSubmit={handleSubmit}>

                        <AvField
                            type="text"
                            label="Mahsulot nomi"
                            name="name"
                            className="form-control mb-2"
                            required
                            validate={{
                                required: {value: true, errorMessage: 'Mahsulot nomini kiriting !'}
                            }}
                        />

                        <AvField
                            type="select"
                            name="category"
                            className="form-select mb-2"
                            label="Kategoriya"
                            validate={{
                                required: {value: true, errorMessage: 'Kategoriyani tanlang !'}
                            }}
                        >
                            <option value="" selected={true} disabled={true}>Kategoriyani tanlang
                            </option>
                            {
                                categories?.length !== 0 ? categories?.map((category, index) => (
                                        <option key={index}
                                                value={category.id}>{category.name}</option>
                                    )) :

                                    <option value="" disabled={true}>Kategoriya
                                        mavjud emas
                                        !</option>
                            }
                        </AvField>

                        <AvField
                            type="textarea"
                            label="Izoh"
                            name="desc"
                            className="form-control mb-2"
                        />

                        <div className="d-flex mt-4">
                            <button id="quit-btn" onClick={handleClose} type="button"
                                    className="w-50 btn btn-light fw-bold">Chiqish
                            </button>
                            <button id="submit-btn" type="submit" className={`w-50 ms-2 fw-bold text-white btn-dark btn`}
                                    disabled={btn_loading}>Ma'lumotlarni saqlash
                            </button>
                        </div>

                    </AvForm>

                </ModalBody>
            </Modal>

        </Fragment>
    );
};

export default AddProduct;