import React, {Fragment, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {addTradePayment, updateTradeForNextPay} from "../../redux/actions/tradesActions";
import CurrencyFormat from "react-currency-format";

const AddTradePayment = ({handleClose, show, toggle}) => {

    const dispatch = useDispatch();

    const {btn_loading, trade} = useSelector(state => state.trades);

    const [pay_type, setPayType] = useState("Oylik to'lov");
    const [date, setDate] = useState();
    const [finish, setFinish] = useState();

    const handleSubmit = (event, data) => {
        if (pay_type === 'Tugatish') {
            data = {
                ...data,
                trade: trade?.id,
                summa: trade?.debt_balance,
                finish: trade?.next_pay
            };
        } else if (pay_type === "Maxsus to'lov") {
            data = {
                ...data,
                trade: trade?.id
            };
        } else {
            data = {
                ...data,
                trade: trade?.id,
                summa: trade?.monthly_pay,
            };
        }
        dispatch(updateTradeForNextPay(trade?.id, {...trade, product: trade?.product?.id, next_pay: data?.finish}));
        dispatch(addTradePayment(trade?.id, data, handleClose));
    };

    return (
        <Fragment>

            <Modal isOpen={show} toggle={toggle} className="add-modal">

                <ModalHeader className="bg-success fw-bold px-4 text-white" toggle={toggle}>To'lov
                    qo'shish</ModalHeader>

                <ModalBody className="p-4">

                    <AvForm className="w-100" onValidSubmit={handleSubmit}>

                        <AvField
                            type="select"
                            name="pay_type"
                            className="form-select mb-2"
                            label="To'lov turi"
                            value={pay_type}
                            onChange={(e) => setPayType(e.target.value)}
                            validate={{
                                required: {value: true, errorMessage: "To'lov turini tanlang !"}
                            }}
                        >
                            <option value="" selected={true} disabled={true}>To'lov turini tanlang</option>
                            <option value="Oylik to'lov">Oylik to'lov</option>
                            <option value="Maxsus to'lov">Maxsus to'lov</option>
                            <option value="Tugatish">Shartnomani yakunlash</option>
                        </AvField>

                        {
                            pay_type === "Maxsus to'lov" && (

                                <>
                                    <AvField
                                        type="number"
                                        label="Summa"
                                        name="summa"
                                        className="form-control mb-2"
                                        required
                                        validate={{
                                            required: {value: true, errorMessage: 'Summani kiriting !'}
                                        }}
                                    />

                                    <AvField
                                        type="date"
                                        label="To'lov sanasi"
                                        name="date"
                                        className="form-control mb-2"
                                        required
                                        value={date}
                                        onChange={(e)=>setDate(e.target.value)}
                                        validate={{
                                            required: {value: true, errorMessage: "To'lov sanasini kiriting !"}
                                        }}
                                    />

                                    <AvField
                                        type="date"
                                        label="Keyingi to'lov sanasi"
                                        name="finish"
                                        className="form-control mb-2"
                                        required
                                        value={finish}
                                        onChange={(e)=>setFinish(e.target.value)}
                                        validate={{
                                            required: {value: true, errorMessage: "Keyingi to'lov sanasini kiriting !"}
                                        }}
                                    />
                                </>

                            )
                        }

                        {
                            pay_type === "Oylik to'lov" && (

                                <>

                                    <div className="my-3">
                                        <label className="me-2">Summa:</label>
                                        <CurrencyFormat value={trade?.monthly_pay?.toFixed(2)}
                                                        displayType={'text'}
                                                        thousandSeparator={" "}
                                                        suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                    </div>

                                    <AvField
                                        type="date"
                                        label="To'lov sanasi"
                                        name="date"
                                        className="form-control mb-3"
                                        required
                                        value={date}
                                        onChange={(e)=>setDate(e.target.value)}
                                        validate={{
                                            required: {value: true, errorMessage: "To'lov sanasini kiriting !"}
                                        }}
                                    />

                                    <AvField
                                        type="date"
                                        label="Keyingi to'lov sanasi"
                                        name="finish"
                                        className="form-control mb-2"
                                        required
                                        value={finish}
                                        onChange={(e)=>setFinish(e.target.value)}
                                        validate={{
                                            required: {value: true, errorMessage: "Keyingi to'lov sanasini kiriting !"}
                                        }}
                                    />
                                </>

                            )
                        }

                        {
                            pay_type === 'Tugatish' && (

                                <>

                                    <div className="my-3">
                                        <label className="me-2">Summa:</label>
                                        <CurrencyFormat value={trade?.debt_balance?.toFixed(2)}
                                                        displayType={'text'}
                                                        thousandSeparator={" "}
                                                        suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                    </div>

                                    <AvField
                                        type="date"
                                        label="To'lov sanasi"
                                        name="date"
                                        className="form-control mb-3"
                                        required
                                        value={date}
                                        onChange={(e)=>setDate(e.target.value)}
                                        validate={{
                                            required: {value: true, errorMessage: "To'lov sanasini kiriting !"}
                                        }}
                                    />
                                </>

                            )
                        }

                        <AvField
                            type="textarea"
                            label="Izoh"
                            name="desc"
                            className="form-control mb-2"
                        />

                        <div className="d-flex mt-4">
                            <button id="quit-btn" onClick={handleClose} type="button"
                                    className="w-50 btn btn-light fw-bold">Chiqish
                            </button>
                            <button id="submit-btn" type="submit"
                                    className={`w-50 ms-2 fw-bold text-white btn-dark btn`}
                                    disabled={btn_loading}>Ma'lumotlarni saqlash
                            </button>
                        </div>

                    </AvForm>

                </ModalBody>
            </Modal>

        </Fragment>
    );
};

export default AddTradePayment;