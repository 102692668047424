import {
    BTN_LOADING_FALSE,
    BTN_LOADING_TRUE,
    LOGIN_FAIL, LOGIN_OUT,
    LOGIN_SUCCESS, REGISTER_FAIL,
    REGISTER_SUCCESS
} from "../actionTypes/actionsTypes";
import {TOKEN_NAME, USER} from "../../tools/constant";

const initialState = {
    accessToken:  localStorage.getItem(TOKEN_NAME) ? JSON.parse(localStorage.getItem(TOKEN_NAME)) : null,
    user: localStorage.getItem(USER) ? JSON.parse(localStorage.getItem(USER)) : null,
    btn_loading: false,
    error: null
};

export const authReducer = (state = initialState, action) => {

    const {type, payload} = action;

    switch (type) {

        case BTN_LOADING_TRUE:
            return {
                ...state,
                btn_loading: true,
            };

        case BTN_LOADING_FALSE:
            return {
                ...state,
                btn_loading: false,
            };

        case LOGIN_SUCCESS:

            return {
                ...state,
                accessToken: payload.accessToken,
                user: payload.user
            };

        case LOGIN_FAIL:

            return {
                ...state,
                accessToken: null,
                user: null,
                error: payload.error
            };

        case LOGIN_OUT:

            return {
                ...state,
                accessToken: null,
                user: null,
            };


        default:
            return state;
    }
};
