import React, {useEffect, useRef, useState} from 'react';
import {ImSearch} from "react-icons/im";
import {AvField, AvForm} from 'availity-reactstrap-validation';
import {getCategoryAndProductData, getTrades, setDeleteTrade, setUpdateTrade} from "../../redux/actions/tradesActions";
import {useDispatch, useSelector} from "react-redux";
import {BiEdit} from "react-icons/bi";
import {RiDeleteBin2Line} from "react-icons/ri";
import {AiFillPrinter, AiOutlineEye, AiOutlinePlus} from "react-icons/ai";
import TableNoData from "../../components/table-no-data/TableNoData";
import CustomPagination from "../../components/custom-pagination/CustomPagination";
import TableLoading from "../../components/table-loading/TableLoading";
import Layout from "../../components/layout/Layout";
import AddTrade from "../../components/admin/AddTrade";
import DeleteTrade from "../../components/admin/DeleteTrade";
import UpdateTrade from "../../components/admin/UpdateTrade";
import {useHistory, useLocation} from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import axios from "axios";
import {PATH_NAME} from "../../tools/constant";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {FiDownload} from "react-icons/fi";

const AdminTrades = () => {

    const componentRef = useRef();

    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);

    const dispatch = useDispatch();

    const {user} = useSelector(state => state.auth);
    const {trades, categories, products, numberOfPages, loading} = useSelector(state => state.trades);

    const [all_trades, setAllTrades] = useState('');

    const [search_condition, setSearchCondition] = useState(false);

    const [page, setPage] = useState(null);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const toggle = () => setShow(!show);

    const handleSubmit = (event, data) => {

        setPage(1);
        searchParams.set('page', 1);
        searchParams.set('product', data.product);
        searchParams.set('date', data.date);
        searchParams.set('client_name', data.client_name);
        searchParams.set('imeika', data.imeika);
        searchParams.set('status', data.status);

        const newSearchString = `?${searchParams.toString()}`;

        history.push({
            pathname: location.pathname,
            search: newSearchString,
        });

        setSearchCondition(!search_condition);
    };

    useEffect(() => {

        const PAGE = searchParams.get('page');

        if (PAGE) {
            setPage(PAGE);
            searchParams.set('page', PAGE);
        } else {
            setPage(1);
            searchParams.set('page', 1);
        }

        const newSearchString = `?${searchParams.toString()}`;

        history.push({
            pathname: location.pathname,
            search: newSearchString,
        });

    }, []);

    useEffect(() => {

        const page = searchParams.get('page') || 1;
        const searchProduct = searchParams.get('product') || '';
        const searchDate = searchParams.get('date') || '';
        const searchName = searchParams.get('client_name') || '';
        const searchIMEI = searchParams.get('imeika') || '';
        const searchStatus = searchParams.get('status') || '';

        dispatch(getTrades(searchProduct, searchName, searchStatus, searchDate, searchIMEI, page));
    }, [page, search_condition]);

    useEffect(() => {
        dispatch(getCategoryAndProductData());
    }, []);

    const setDeleteTradeFunc = (trade) => {
        dispatch(setDeleteTrade(trade));
    };

    const setUpdateTradeFunc = (trade) => {
        dispatch(setUpdateTrade(trade));
    };

    const getTradesRequest = () => {

        setPage(1);
        searchParams.set('page', 1);

        const newSearchString = `?${searchParams.toString()}`;

        history.push({
            pathname: location.pathname,
            search: newSearchString,
        });

        const page = searchParams.get('page') || 1;
        const searchProduct = searchParams.get('product') || '';
        const searchDate = searchParams.get('date') || '';
        const searchName = searchParams.get('client_name') || '';
        const searchIMEI = searchParams.get('imeika') || '';
        const searchStatus = searchParams.get('status') || '';

        dispatch(getTrades(searchProduct, searchName, searchStatus, searchDate, searchIMEI, page));
    };

    useEffect(() => {

        const searchProduct = searchParams.get('product') || '';
        const searchDate = searchParams.get('date') || '';
        const searchName = searchParams.get('client_name') || '';
        const searchIMEI = searchParams.get('imeika') || '';
        const searchStatus = searchParams.get('status') || '';

        // axios.get(PATH_NAME + `/api/alltrade/?product=${searchProduct}&client_name=${searchName}&status=${searchStatus}&next_pay=${searchDate}&search=${searchIMEI}`).then((res) => {
        //     setAllTrades(res.data);
        //     console.log(res.data);
        // }).catch((err) => {
        //     console.log(err);
        // });

        axios.get(PATH_NAME + `/api/alltrade/`).then((res) => {
            setAllTrades(res.data);
            console.log(res.data);
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const tableRef = useRef(null);

    const [currentTime, setCurrentTime] = useState('');

    useEffect(() => {
        const intervalId = setInterval(() => {
            const now = new Date();
            const formattedTime = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')} ${now.getHours().toString().padStart(2, '0')}:${now.getMinutes().toString().padStart(2, '0')}:${now.getSeconds().toString().padStart(2, '0')}`;
            setCurrentTime(formattedTime);
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    return (
        <Layout>
            <div className="Groups min-vh-100 mb-5 mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-xl-flex align-items-center justify-content-between d-block">
                                <h2 className="text-dark fw-bold text-center mb-4 mb-xl-0">Savdolar</h2>
                                <div className="d-xl-block align-items-center justify-content-between d-flex">
                                    <ReactHTMLTableToExcel
                                        id="test-table-xls-button"
                                        className="me-2 btn-dark btn"
                                        table="table-to-xls-data"
                                        filename={`Savdolar - ${currentTime}`}
                                        sheet="savdolar"
                                        style={{fontSize: "30px"}}
                                        buttonText={
                                            <div>
                                                <FiDownload/> Yuklab olish
                                            </div>
                                        }
                                    />
                                    <ReactToPrint
                                        trigger={() => <button className="btn btn-dark me-2">CHOP ETISH <AiFillPrinter
                                            className="ms-2"/></button>}
                                        content={() => componentRef.current}

                                        pageStyle={`
                                                    @page {
                                                      size: A4;
                                                      margin: 50px;
                                                      padding: 0px;
                                                 
                                                      /*margin-bottom: 50px;
                                                      margin-top: 50px;*/
                                                    }
                                                    body {
                                                      background-color: red;
                                                      margin: 50px;
                                                      padding: 0px;
                                                    
                                                      /*margin-top: 50px;
                                                      margin-bottom: 50px;*/
                                                      font-family: "Youtube";
                                                    }
                                                `}
                                    />
                                    <button onClick={() => handleShow()} className="btn btn-success fw-bold">
                                        SAVDO
                                        <AiOutlinePlus className="ms-2"/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">

                            <AvForm className="my-5" onValidSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-2">
                                        <AvField
                                            value={searchParams.get('product')}
                                            type="select"
                                            name="product"
                                            className="form-select mb-2 mb-sm-0"
                                        >
                                            <option value="" selected={true}>Mahsulotni tanlang
                                            </option>
                                            {
                                                products?.length !== 0 ? products?.map((product, index) => (
                                                        <option key={index}
                                                                value={product.id}>{product.name}</option>
                                                    )) :

                                                    <option value="" disabled={true}>Mahsulot
                                                        mavjud emas
                                                        !</option>
                                            }
                                        </AvField>
                                    </div>
                                    <div className="col-md-2">
                                        <AvField
                                            value={searchParams.get('client_name')}
                                            type="text"
                                            name="client_name"
                                            placeholder="Mijoz nomi"
                                            className="form-control mb-2 mb-sm-0"
                                        />
                                    </div>

                                    <div className="col-md-2">
                                        <AvField
                                            value={searchParams.get('date')}
                                            type="date"
                                            name="date"
                                            placeholder="Sana"
                                            className="form-control mb-2 mb-sm-0"
                                        />
                                    </div>

                                    <div className="col-md-2">
                                        <AvField
                                            value={searchParams.get('imeika')}
                                            type="text"
                                            name="imeika"
                                            placeholder="IMEI | Mijoz raqami"
                                            className="form-control mb-2 mb-sm-0"
                                        />
                                    </div>

                                    <div className="col-md-2">

                                        <AvField
                                            value={searchParams.get('status')}
                                            type="select"
                                            name="status"
                                            className="form-select mb-2 mb-sm-0"
                                        >
                                            <option value="" selected={true}>Holatni tanlang</option>
                                            <option value="Qarzdorlik">Qarzdorlik</option>
                                            <option value="To'langan">To'langan</option>
                                            <option value="Shartnoma yakunlangan">Shartnoma yakunlangan</option>
                                        </AvField>

                                    </div>
                                    <div className="col-md-2">
                                        <button className="btn btn-dark w-100">QIDIRISH <ImSearch className="ms-2"/>
                                        </button>
                                    </div>
                                </div>
                            </AvForm>

                        </div>

                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-bordered table-hover text-center">
                                    <thead>
                                    <tr>
                                        <th>№</th>
                                        <th>Mijoz nomi</th>
                                        <th>Mahsulot</th>
                                        <th>Muddat</th>
                                        <th>Qarz miqdori</th>
                                        <th>Navbatdagi to'lov sanasi</th>
                                        <th>Status</th>
                                        <th>Sozlamalar</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        loading ?
                                            <tr>
                                                <td colSpan={12}>
                                                    <TableLoading/>
                                                </td>
                                            </tr> :

                                            trades.length > 0 ? trades?.map((trade, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {(page - 1) * 10 + index + 1}
                                                        </td>
                                                        <td>{trade.client_name}</td>
                                                        <td>{trade?.product?.name}</td>

                                                        <td>
                                                            {trade.dedline} oy
                                                        </td>

                                                        <td className="text-danger fw-bold">
                                                            <CurrencyFormat value={trade?.debt_balance?.toFixed(2)}
                                                                            displayType={'text'}
                                                                            thousandSeparator={" "}
                                                                            suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                                        </td>

                                                        <td>
                                                            {
                                                                trade.status === "Shartnoma yakunlangan" ?

                                                                    <>

                                                                    </> :

                                                                    <>
                                                                        {trade?.next_pay?.slice(0, 11)}
                                                                    </>

                                                            }
                                                        </td>

                                                        <td>
                                                            {trade.status === "To'langan" ?
                                                                <>
                                                                    <div
                                                                        className={trade.status === "To'langan" ? "fw-bold text-success" : trade.status === "Qarzdorlik" ? "fw-bold text-danger" : "fw-bold text-warning"}>
                                                                        {trade.status}
                                                                    </div>
                                                                    <hr/>
                                                                    <i>Keyingi to'lovga <b>{trade?.pay_day}</b> kun
                                                                        qoldi.</i>
                                                                </> :

                                                                trade.status === "Qarzdorlik" ?

                                                                    <>
                                                                        <div
                                                                            className={trade.status === "To'langan" ? "fw-bold text-success" : trade.status === "Qarzdorlik" ? "fw-bold text-danger" : "fw-bold text-warning"}>
                                                                            {trade.status}
                                                                        </div>
                                                                        <hr/>
                                                                        <i>To'lov sanasidan <b>{trade?.pay_day}</b> kun
                                                                            o'tib ketdi.</i>
                                                                    </> :

                                                                    <>
                                                                        <div
                                                                            className={trade.status === "To'langan" ? "fw-bold text-success" : trade.status === "Qarzdorlik" ? "fw-bold text-danger" : "fw-bold text-warning"}>
                                                                            {trade.status}
                                                                        </div>
                                                                    </>

                                                            }
                                                        </td>

                                                        <td>
                                                            <div
                                                                className="justify-content-around d-flex align-items-center">
                                                                <button
                                                                    onClick={() => history.push(`/admin/trades/${trade.id}`)}
                                                                    className="btn btn-dark btn-sm">
                                                                    <AiOutlineEye/>
                                                                </button>
                                                                <button onClick={() => setUpdateTradeFunc(trade)}
                                                                        className="btn btn-warning btn-sm mx-2">
                                                                    <BiEdit/>
                                                                </button>
                                                                <button onClick={() => setDeleteTradeFunc(trade)}
                                                                        className="btn btn-danger btn-sm">
                                                                    <RiDeleteBin2Line/>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )) :

                                                <tr>
                                                    <th colSpan={12}>
                                                        <TableNoData text="Savdo topilmadi !!!"/>
                                                    </th>
                                                </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>

                            {numberOfPages > 1 ?

                                <CustomPagination withSearchPage={true} numberOfPages={numberOfPages}
                                                  current_page={page}
                                                  setCurrentPage={setPage}/> : ""
                            }
                        </div>

                        {/*// print*/}
                        <div style={{display: "none"}}>
                            <div ref={componentRef} className="">

                                <div className="mb-4 d-flex align-items-center justify-content-center"
                                     style={{fontSize: "25px"}}>
                                    <div className="d-flex justify-content-center align-items-center flex-column">
                                        <div className="fw-bold">
                                            Savdolar
                                        </div>
                                        <p style={{fontSize: "16px"}}>{currentTime}</p>
                                    </div>
                                </div>

                                <table align={"center"} className="table table-bordered text-center"
                                       style={{fontSize: "16px", borderRadius: "10px"}}>
                                    <thead style={{backgroundColor: "#6691E7", color: "white", fontWeight: "bold"}}>
                                    <tr>
                                        <th>№</th>
                                        <th>Mijoz</th>
                                        <th>Mahsulot</th>
                                        <th>Savdo</th>
                                        <th>Status</th>
                                        <th>To'lovlar</th>
                                    </tr>
                                    </thead>

                                    <tbody style={{backgroundColor: "white"}}>
                                    {
                                        loading ?
                                            <tr>
                                                <td colSpan={12}>
                                                    <TableLoading/>
                                                </td>
                                            </tr> :

                                            all_trades.length > 0 ? all_trades?.map((trade, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {index + 1}
                                                        </td>
                                                        <td>

                                                            <div className="fw-bold">MIJOZ NOMI</div>
                                                            {trade.client_name}
                                                            <hr/>
                                                            <div className="fw-bold">TELEFON</div>
                                                            {trade.client_num}
                                                            <hr/>
                                                            <div className="fw-bold">PASSPORT</div>
                                                            {trade.client_passport}

                                                        </td>
                                                        <td>
                                                            <div className="fw-bold">NOMI</div>
                                                            {trade?.product?.name}
                                                            <hr/>
                                                            <div className="fw-bold">IMEI</div>
                                                            {trade?.imeika}
                                                        </td>

                                                        <td>

                                                            <div className="fw-bold">SAVDO SANASI</div>
                                                            {trade?.start?.slice(0, 11)}
                                                            <hr/>
                                                            <div className="fw-bold">MUDDAT</div>
                                                            {trade.dedline} oy
                                                            <hr/>

                                                            <div className="fw-bold">NARXI</div>
                                                            <CurrencyFormat value={trade?.price?.toFixed(2)}
                                                                            displayType={'text'}
                                                                            thousandSeparator={" "}
                                                                            suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                                            <hr/>

                                                            <div className="fw-bold">USTAMA</div>
                                                            <CurrencyFormat value={trade?.surcharge?.toFixed(2)}
                                                                            displayType={'text'}
                                                                            thousandSeparator={" "}
                                                                            suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                                            <hr/>

                                                            <div className="fw-bold">USTAMA BILAN NARXI</div>
                                                            <CurrencyFormat value={trade?.total?.toFixed(2)}
                                                                            displayType={'text'}
                                                                            thousandSeparator={" "}
                                                                            suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                                            <hr/>

                                                            <div className="fw-bold">OLDINDAN TO'LOV</div>
                                                            <CurrencyFormat value={trade?.ad_payment?.toFixed(2)}
                                                                            displayType={'text'}
                                                                            thousandSeparator={" "}
                                                                            suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                                        </td>
                                                        <td>
                                                            {trade.status === "To'langan" ?
                                                                <>
                                                                    <div
                                                                        className={trade.status === "To'langan" ? "fw-bold text-success" : trade.status === "Qarzdorlik" ? "fw-bold text-danger" : "fw-bold text-warning"}>
                                                                        {trade.status}
                                                                    </div>
                                                                    <hr/>
                                                                    <i>Keyingi to'lovga {trade?.pay_day} kun qoldi.</i>
                                                                </> :

                                                                trade.status === "Qarzdorlik" ?

                                                                    <>
                                                                        <div
                                                                            className={trade.status === "To'langan" ? "fw-bold text-success" : trade.status === "Qarzdorlik" ? "fw-bold text-danger" : "fw-bold text-warning"}>
                                                                            {trade.status}
                                                                        </div>
                                                                        <hr/>
                                                                        <i>To'lov sanasidan {trade?.pay_day} kun o'tib
                                                                            ketdi.</i>
                                                                    </> :

                                                                    <>
                                                                        <div
                                                                            className={trade.status === "To'langan" ? "fw-bold text-success" : trade.status === "Qarzdorlik" ? "fw-bold text-danger" : "fw-bold text-warning"}>
                                                                            {trade.status}
                                                                        </div>
                                                                    </>

                                                            }

                                                            <hr/>

                                                            <div className="fw-bold">NAVBATDAGI TO'LOV SANASI</div>
                                                            {
                                                                trade.status === "Shartnoma yakunlangan" ?

                                                                    <>

                                                                    </> :

                                                                    <>
                                                                        {trade?.next_pay?.slice(0, 11)}
                                                                    </>

                                                            }
                                                            <hr/>

                                                            <div className="fw-bold">To'langan summa</div>
                                                            <CurrencyFormat value={trade?.common_payment?.toFixed(2)}
                                                                            displayType={'text'}
                                                                            thousandSeparator={" "}
                                                                            suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                                            <hr/>
                                                            <div className="fw-bold">Qolgan summa</div>
                                                            <CurrencyFormat value={trade?.debt_balance?.toFixed(2)}
                                                                            displayType={'text'}
                                                                            thousandSeparator={" "}
                                                                            suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                                        </td>

                                                        <td>
                                                            <div className="table-responsive">
                                                                <table
                                                                    className="table table-bordered table-hover text-center">
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="col-2">№</th>
                                                                        <th>Summa</th>
                                                                        <th>Sana</th>
                                                                        <th>Keyingi to'lov sanasi</th>
                                                                        <th>Izoh</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {

                                                                        trade?.payments?.length > 0 ? trade?.payments?.map((payment, index) => (
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        {index + 1}
                                                                                    </td>
                                                                                    <td>
                                                                                        <CurrencyFormat
                                                                                            value={payment?.summa?.toFixed(2)}
                                                                                            displayType={'text'}
                                                                                            thousandSeparator={" "}
                                                                                            suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                                                                    </td>

                                                                                    <td>{payment?.date?.slice(0, 11)}</td>
                                                                                    <td>
                                                                                        {
                                                                                            payment?.pay_type === "Tugatish"
                                                                                                ? "Shartnoma yakunlagan !!!"
                                                                                                : payment?.finish?.slice(0, 11)
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {payment?.desc}
                                                                                    </td>
                                                                                </tr>
                                                                            )) :

                                                                            <tr>
                                                                                <th colSpan={10}>
                                                                                    <div style={{
                                                                                        height: "50px",
                                                                                        width: "100%"
                                                                                    }}
                                                                                         className="d-flex align-items-center justify-content-center">
                                                                                        <h6>To'lovlar topilmadi !!!</h6>
                                                                                    </div>
                                                                                </th>
                                                                            </tr>

                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            {/*<hr/>

                                                            <div className="fw-bold">IZOH</div>
                                                            {trade.desc}*/}

                                                        </td>
                                                    </tr>
                                                )) :

                                                <tr>
                                                    <th colSpan={12}>
                                                        <TableNoData text="Savdo topilmadi !!!"/>
                                                    </th>
                                                </tr>
                                    }

                                    </tbody>

                                </table>
                            </div>
                        </div>

                        {/*//exel*/}
                        <div className="d-none">
                            <table ref={tableRef} id="table-to-xls-data">
                                <thead>
                                <tr>
                                    <th colSpan={6}>
                                        Savdolar: {currentTime}
                                    </th>
                                </tr>
                                <tr>
                                    <th>№</th>
                                    <th>Mijoz</th>
                                    <th>Mahsulot</th>
                                    <th>Savdo</th>
                                    <th>Status</th>
                                    <th>To'lovlar</th>
                                </tr>
                                </thead>

                                <tbody>
                                {
                                    loading ?
                                        <tr>
                                            <td colSpan={12}>
                                                <TableLoading/>
                                            </td>
                                        </tr> :

                                        all_trades.length > 0 ? all_trades?.map((trade, index) => (
                                                <>
                                                    <tr key={index}>
                                                        <td>
                                                            {index + 1}
                                                        </td>
                                                        <td>

                                                            <div className="fw-bold">MIJOZ NOMI: {trade.client_name}</div>
                                                            <br/>
                                                            <div className="fw-bold">TELEFON: {trade.client_num}</div>
                                                            <br/>
                                                            <div className="fw-bold">PASSPORT: {trade.client_passport}</div>

                                                        </td>
                                                        <td>
                                                            <div className="fw-bold">NOMI: {trade?.product?.name}</div>
                                                            <br/>
                                                            <div className="fw-bold">IMEI: {trade?.imeika}</div>
                                                        </td>

                                                        <td>

                                                            <div className="fw-bold">SAVDO
                                                                SANASI: {trade?.start?.slice(0, 11)}</div>
                                                            <br/>
                                                            <div className="fw-bold">MUDDAT: {trade.dedline} oy</div>
                                                            <br/>

                                                            <div className="fw-bold">
                                                                NARXI: <CurrencyFormat value={trade?.price?.toFixed(2)}
                                                                                       displayType={'text'}
                                                                                       thousandSeparator={" "}
                                                                                       suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                                            </div>
                                                            <br/>

                                                            <div className="fw-bold">
                                                                USTAMA: <CurrencyFormat value={trade?.surcharge?.toFixed(2)}
                                                                                        displayType={'text'}
                                                                                        thousandSeparator={" "}
                                                                                        suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                                            </div>
                                                            <br/>

                                                            <div className="fw-bold">
                                                                USTAMA BILAN NARXI: <CurrencyFormat
                                                                value={trade?.total?.toFixed(2)}
                                                                displayType={'text'}
                                                                thousandSeparator={" "}
                                                                suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                                            </div>
                                                            <br/>

                                                            <div className="fw-bold">
                                                                OLDINDAN TO'LOV: <CurrencyFormat
                                                                value={trade?.ad_payment?.toFixed(2)}
                                                                displayType={'text'}
                                                                thousandSeparator={" "}
                                                                suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {trade.status === "To'langan" ?
                                                                <>
                                                                    <div
                                                                        className={trade.status === "To'langan" ? "fw-bold text-success" : trade.status === "Qarzdorlik" ? "fw-bold text-danger" : "fw-bold text-warning"}>
                                                                        {trade.status}
                                                                    </div>
                                                                    <br/>
                                                                    <i>Keyingi to'lovga {trade?.pay_day} kun qoldi.</i>
                                                                </> :

                                                                trade.status === "Qarzdorlik" ?

                                                                    <>
                                                                        <div
                                                                            className={trade.status === "To'langan" ? "fw-bold text-success" : trade.status === "Qarzdorlik" ? "fw-bold text-danger" : "fw-bold text-warning"}>
                                                                            {trade.status}
                                                                        </div>
                                                                        <br/>
                                                                        <i>To'lov sanasidan {trade?.pay_day} kun o'tib
                                                                            ketdi.</i>
                                                                    </> :

                                                                    <>
                                                                        <div
                                                                            className={trade.status === "To'langan" ? "fw-bold text-success" : trade.status === "Qarzdorlik" ? "fw-bold text-danger" : "fw-bold text-warning"}>
                                                                            {trade.status}
                                                                        </div>
                                                                    </>

                                                            }

                                                            <br/>
                                                            <br/>

                                                            <div className="fw-bold">
                                                                NAVBATDAGI TO'LOV SANASI:
                                                                {
                                                                    trade.status === "Shartnoma yakunlangan" ?

                                                                        <>

                                                                        </> :

                                                                        <>
                                                                            {trade?.next_pay?.slice(0, 11)}
                                                                        </>

                                                                }
                                                            </div>
                                                            <br/>

                                                            <div className="fw-bold">
                                                                To'langan summa: <CurrencyFormat
                                                                value={trade?.common_payment?.toFixed(2)}
                                                                displayType={'text'}
                                                                thousandSeparator={" "}
                                                                suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                                            </div>
                                                            <br/>
                                                            <div className="fw-bold">
                                                                Qolgan summa: <CurrencyFormat
                                                                value={trade?.debt_balance?.toFixed(2)}
                                                                displayType={'text'}
                                                                thousandSeparator={" "}
                                                                suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                                            </div>
                                                        </td>

                                                        <td>
                                                            <div className="table-responsive">
                                                                <table
                                                                    className="table table-bordered table-hover text-center">
                                                                    <thead>
                                                                    <tr>
                                                                        <th className="col-2">№</th>
                                                                        <th>Summa</th>
                                                                        <th>Sana</th>
                                                                        <th>Keyingi to'lov sanasi</th>
                                                                        <th>Izoh</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {

                                                                        trade?.payments?.length > 0 ? trade?.payments?.map((payment, index) => (
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        {index + 1}
                                                                                    </td>
                                                                                    <td>
                                                                                        <CurrencyFormat
                                                                                            value={payment?.summa?.toFixed(2)}
                                                                                            displayType={'text'}
                                                                                            thousandSeparator={" "}
                                                                                            suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/>
                                                                                    </td>

                                                                                    <td>{payment?.date?.slice(0, 11)}</td>
                                                                                    <td>
                                                                                        {
                                                                                            payment?.pay_type === "Tugatish"
                                                                                                ? "Shartnoma yakunlagan !!!"
                                                                                                : payment?.finish?.slice(0, 11)
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {payment?.desc}
                                                                                    </td>
                                                                                </tr>
                                                                            )) :

                                                                            <tr>
                                                                                <th colSpan={5}>
                                                                                    <div style={{
                                                                                        height: "50px",
                                                                                        width: "100%"
                                                                                    }}
                                                                                         className="d-flex align-items-center justify-content-center">
                                                                                        <h6>To'lovlar topilmadi !!!</h6>
                                                                                    </div>
                                                                                </th>
                                                                            </tr>

                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                            {/*<hr/>

                                                            <div className="fw-bold">IZOH</div>
                                                            {trade.desc}*/}

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={6}>

                                                        </td>
                                                    </tr>
                                                </>

                                            )) :

                                            <tr>
                                                <th colSpan={6}>
                                                    <TableNoData text="Savdo topilmadi !!!"/>
                                                </th>
                                            </tr>
                                }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>

            <AddTrade handleClose={handleClose} show={show} toggle={toggle} getTradesRequest={getTradesRequest}/>

            <DeleteTrade getTradesRequest={getTradesRequest}/>
            <UpdateTrade getTradesRequest={getTradesRequest}/>
        </Layout>
    );
};

export default AdminTrades;