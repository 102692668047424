import React, {Fragment} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {addCategory} from "../../redux/actions/categoriesActions";

const AddCategory = ({handleClose, show, toggle}) => {

    const dispatch = useDispatch();

    const {btn_loading} = useSelector(state => state.categories);

    const handleSubmit = (event, data) => {
        dispatch(addCategory(data, handleClose));
    };

    return (
        <Fragment>

            <Modal isOpen={show} toggle={toggle} className="add-modal">

                <ModalHeader className="bg-success fw-bold px-4 text-white" toggle={toggle}>Kategoriya qo'shish</ModalHeader>

                <ModalBody className="p-4">

                    <AvForm className="w-100" onValidSubmit={handleSubmit}>

                        <AvField
                            type="text"
                            label="Kategoriya nomi"
                            name="name"
                            className="form-control mb-2"
                            required
                            validate={{
                                required: {value: true, errorMessage: 'Kategoriya nomini kiriting !'}
                            }}
                        />

                        <div className="d-flex mt-4">
                            <button id="quit-btn" onClick={handleClose} type="button"
                                    className="w-50 btn btn-light fw-bold">Chiqish
                            </button>
                            <button id="submit-btn" type="submit" className={`w-50 ms-2 fw-bold text-white btn-dark btn`}
                                    disabled={btn_loading}>Ma'lumotlarni saqlash
                            </button>
                        </div>

                    </AvForm>

                </ModalBody>
            </Modal>

        </Fragment>
    );
};

export default AddCategory;