import React from 'react';
import { SemipolarLoading } from 'react-loadingg';

function SiteLoading(props) {
    return (
        <div className="SiteLoader">
            <SemipolarLoading size={"large"} color={"#212529"}/>
        </div>
    );
}

export default SiteLoading;