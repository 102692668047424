import {
    BTN_LOADING_FALSE,
    BTN_LOADING_TRUE, GET_CATEGORIES,
    GET_ROOMS,
    LOADING_FALSE,
    LOADING_TRUE, SET_DELETE_CATEGORY,
    SET_DELETE_ROOM, SET_UPDATE_CATEGORY,
    SET_UPDATE_ROOM
} from "../actionTypes/actionsTypes";
import axios from "axios";
import {toast} from "react-toastify";
import {PATH_NAME, TOKEN_NAME} from "../../tools/constant";

export const getCategories = () => async (dispatch) => {

    dispatch({
        type: LOADING_TRUE
    });

    try {

        const res = await axios.get(PATH_NAME + `/api/category/`);

        dispatch({
            type: GET_CATEGORIES,
            payload: {
                categories: res.data,
            }
        });

        dispatch({
            type: LOADING_FALSE,
        });

    } catch (err) {
        dispatch({
            type: LOADING_FALSE,
        });
    }

};


export const addCategory = (data, handleClose) => async (dispatch) => {

    dispatch({
        type: BTN_LOADING_TRUE
    });

    try {

        const accessToken = JSON.parse(localStorage.getItem(TOKEN_NAME));

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const res = await axios.post(`${PATH_NAME}/api/category/`, data, config);

        handleClose();

        dispatch(getCategories());

        toast.success(`Kategoriya muvaffaqiyatli yaratildi !!!`);

    } catch (err) {

        dispatch({
            type: BTN_LOADING_FALSE,
        });

        console.log(err.status);

        toast.error(`Ma'lumot yaratilmadi !!!`);
    } finally {
        dispatch({
            type: BTN_LOADING_FALSE,
        });
    }

};

export const setDeleteCategory = (category) => async (dispatch) => {

    try {

        dispatch({
            type: SET_DELETE_CATEGORY,
            payload: category
        });

    } catch (err) {
        console.log(err)
    }
};

export const setUpdateCategory = (category) => async (dispatch) => {

    try {

        dispatch({
            type: SET_UPDATE_CATEGORY,
            payload: category
        });

    } catch (err) {
        console.log(err)
    }
};

export const deleteCategory = (categoryID, handleClose) => async (dispatch) => {

    dispatch({
        type: BTN_LOADING_TRUE
    });

    try {

        const accessToken = JSON.parse(localStorage.getItem(TOKEN_NAME));

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const res = await axios.delete(`${PATH_NAME}/api/category/${categoryID}/`, config);

        handleClose();

        toast.success(`Kategoriya muvaffaqiyatli o'chirildi !!!`);

        dispatch(getCategories());

    } catch (err) {

        console.log(err.status);

        dispatch({
            type: BTN_LOADING_FALSE,
        });

        toast.error(`Ma'lumot o'chirilmadi !!!`);
    } finally {
        dispatch({
            type: BTN_LOADING_FALSE,
        });
    }

};

export const updateCategory = (categoryID, data, handleClose) => async (dispatch) => {

    dispatch({
        type: BTN_LOADING_TRUE
    });

    try {

        const accessToken = JSON.parse(localStorage.getItem(TOKEN_NAME));

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const res = await axios.patch(`${PATH_NAME}/api/category/${categoryID}/`, data, config);

        handleClose();

        toast.success(`Kategoriya muvaffaqiyatli tahrirlandi !!!`);

        dispatch(getCategories());

    } catch (err) {

        dispatch({
            type: BTN_LOADING_FALSE,
        });

        console.log(err.status);

        toast.error(`Ma'lumot o'zgartirilmadi !!!`);
    } finally {
        dispatch({
            type: BTN_LOADING_FALSE,
        });
    }

};
