import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {REMOVE_DELETE_CATEGORY} from "../../redux/actionTypes/actionsTypes";
import {deleteCategory} from "../../redux/actions/categoriesActions";

const DeleteCategory = () => {

    const dispatch = useDispatch();

    const {delete_category, btn_loading} = useSelector(state => state.categories);

    const closeModal = () =>{
        dispatch({
            type: REMOVE_DELETE_CATEGORY
        })
    };

    const onConfirm = () =>{
        dispatch(deleteCategory(delete_category?.id, closeModal))
    };

    let condition = false;
    if(delete_category !== null)
        condition = true;

    return (
        <div>
            <Modal isOpen={condition} toggle={closeModal}>
                <ModalHeader className="bg-danger text-white fw-bold" toggle={closeModal}>Kategoriyani o'chirish</ModalHeader>
                <ModalBody>
                    Siz haqiqatdan ham ushbu <b>{delete_category?.name}</b> nomli kategoriyani o'chirmoqchimisiz ?
                </ModalBody>

                <ModalFooter>
                    <button id="quit-btn" onClick={closeModal} className="btn btn-light fw-bold">YO'Q</button>
                    <button id="submit-btn" disabled={btn_loading} onClick={onConfirm} className="btn btn-dark fw-bold text-white">HA</button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DeleteCategory;