import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {REMOVE_DELETE_TRADE, REMOVE_DELETE_TRADE_PAYMENT} from "../../redux/actionTypes/actionsTypes";
import {deleteTrade, deleteTradePayment, updateTradeForNextPay} from "../../redux/actions/tradesActions";
import CurrencyFormat from "react-currency-format";

const DeleteTradePayment = () => {

    const dispatch = useDispatch();

    const {delete_trade_payment, tradePayments, trade, btn_loading} = useSelector(state => state.trades);

    const closeModal = () => {
        dispatch({
            type: REMOVE_DELETE_TRADE_PAYMENT
        })
    };

    const onConfirm = () => {

        if (tradePayments.length === 1){

            const birOyKeyingiSana = new Date(trade.start);
            birOyKeyingiSana.setMonth(birOyKeyingiSana.getMonth() + 1);

            const year = birOyKeyingiSana.getFullYear();
            const month = (birOyKeyingiSana.getMonth() + 1).toString().padStart(2, '0');
            const day = birOyKeyingiSana.getDate().toString().padStart(2, '0');

            const formattedDate = `${year}-${month}-${day}`;

            dispatch(updateTradeForNextPay(trade?.id, {...trade, product: trade?.product?.id, next_pay: formattedDate}));

        } else if (tradePayments.length > 1 && tradePayments.indexOf(delete_trade_payment) === 0){

            dispatch(updateTradeForNextPay(trade?.id, {...trade, product: trade?.product?.id, next_pay: tradePayments[1]?.finish}));
        }

        dispatch(deleteTradePayment(trade?.id, delete_trade_payment?.id, closeModal))
    };

    let condition = false;
    if (delete_trade_payment !== null)
        condition = true;

    return (
        <div>
            <Modal isOpen={condition} toggle={closeModal}>
                <ModalHeader className="bg-danger text-white fw-bold" toggle={closeModal}>To'lovni
                    o'chirish</ModalHeader>
                <ModalBody>
                    Siz haqiqatdan ham ushbu <b> <CurrencyFormat value={delete_trade_payment?.summa}
                                                                 displayType={'text'}
                                                                 thousandSeparator={" "}
                                                                 suffix={` ${trade?.type_pay?.toLowerCase() === "dollar" ? '$' : "so'm"}`}/> </b> to'lovni
                    o'chirmoqchimisiz ?
                </ModalBody>

                <ModalFooter>
                    <button id="quit-btn" onClick={closeModal} className="btn btn-light fw-bold">YO'Q</button>
                    <button id="submit-btn" disabled={btn_loading} onClick={onConfirm}
                            className="btn btn-dark fw-bold text-white">HA
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DeleteTradePayment;