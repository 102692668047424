import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {REMOVE_DELETE_PRODUCT} from "../../redux/actionTypes/actionsTypes";
import {deleteProduct} from "../../redux/actions/productsActions";

const DeleteProduct = ({getProductsRequest}) => {

    const dispatch = useDispatch();

    const {delete_product, btn_loading} = useSelector(state => state.products);

    const closeModal = () =>{
        dispatch({
            type: REMOVE_DELETE_PRODUCT
        })
    };

    const onConfirm = () =>{
        dispatch(deleteProduct(delete_product?.id, closeModal, getProductsRequest))
    };

    let condition = false;
    if(delete_product !== null)
        condition = true;

    return (
        <div>
            <Modal isOpen={condition} toggle={closeModal}>
                <ModalHeader className="bg-danger text-white fw-bold" toggle={closeModal}>Mahsulotni o'chirish</ModalHeader>
                <ModalBody>
                    Siz haqiqatdan ham ushbu <b>{delete_product?.name}</b> nomli mahsulotni o'chirmoqchimisiz ?
                </ModalBody>

                <ModalFooter>
                    <button id="quit-btn" onClick={closeModal} className="btn btn-light fw-bold">YO'Q</button>
                    <button id="submit-btn" disabled={btn_loading} onClick={onConfirm} className="btn btn-dark fw-bold text-white">HA</button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default DeleteProduct;