import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {BiEdit} from "react-icons/bi";
import {RiDeleteBin2Line} from "react-icons/ri";
import {AiOutlinePlus} from "react-icons/ai";
import Layout from "../../components/layout/Layout";
import TableNoData from "../../components/table-no-data/TableNoData";
import TableLoading from "../../components/table-loading/TableLoading";
import {getCategories, setDeleteCategory, setUpdateCategory} from "../../redux/actions/categoriesActions";
import AddCategory from "../../components/admin/AddCategory";
import DeleteCategory from "../../components/admin/DeleteCategory";
import UpdateCategory from "../../components/admin/UpdateCategory";

const AdminCategories = () => {

    const dispatch = useDispatch();

    const {categories, loading} = useSelector(state => state.categories);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const toggle = () => setShow(!show);

    useEffect(() => {
        dispatch(getCategories());
    }, []);

    const setDeleteCategoryFunc =(category)=>{
        dispatch(setDeleteCategory(category));
    };

    const setUpdateCategoryFunc =(category)=>{
        dispatch(setUpdateCategory(category));
    };

    return (
        <Layout>
            <div className="Rooms min-vh-100 mb-5 mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2 className="text-dark fw-bold text-center">Kategoriyalar</h2>
                                <button onClick={()=>handleShow()} className="btn btn-success fw-bold">
                                    Kategoriya
                                    <AiOutlinePlus className="ms-2"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row my-5">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-bordered table-hover text-center">
                                    <thead>
                                    <tr>
                                        <th className="col-2">№</th>
                                        <th>Kategoriya nomi</th>
                                        <th className="col-2">Sozlamalar</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        loading ?
                                            <tr>
                                                <td colSpan={10}>
                                                    <TableLoading/>
                                                </td>
                                            </tr> :

                                            categories?.length > 0 ? categories?.map((category, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {index + 1}
                                                        </td>
                                                        <td>{category?.name}</td>
                                                        <td className="justify-content-around d-flex align-items-center">
                                                            <button onClick={()=>setUpdateCategoryFunc(category)} className="btn btn-warning btn-sm">
                                                                <BiEdit/>
                                                            </button>
                                                            <button onClick={()=>setDeleteCategoryFunc(category)} className="btn btn-danger btn-sm">
                                                                <RiDeleteBin2Line/>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )) :

                                                <tr>
                                                    <th colSpan={10}>
                                                        <TableNoData text="Kategoriya topilmadi !!!"/>
                                                    </th>
                                                </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AddCategory handleClose={handleClose} show={show} toggle={toggle}/>

            <DeleteCategory/>

            <UpdateCategory/>

        </Layout>
    );
};

export default AdminCategories;