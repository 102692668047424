import React from 'react';
import Pagination from "@mui/material/Pagination/Pagination";
import "./Pagination.scss";
import {useHistory, useLocation} from 'react-router-dom';

const CustomPagination = ({current_page, setCurrentPage, numberOfPages, withSearchPage=false}) => {

    const location = useLocation();
    const history = useHistory();

    const searchParams = new URLSearchParams(location.search);

    const handleChangePage = (event, newPage) => {

        setCurrentPage(newPage);

        if (withSearchPage){
            searchParams.set('page', newPage);

            const newSearchString = `?${searchParams.toString()}`;

            history.push({
                pathname: location.pathname,
                search: newSearchString,
            });
        }
    };

    return (
        <div className="d-flex align-items-center justify-content-center my-5">
            <Pagination
                count={numberOfPages}
                variant="outlined"
                shape="rounded"
                page={Number.parseInt(current_page)}
                onChange={handleChangePage}
            />
        </div>
    );
};

export default CustomPagination;