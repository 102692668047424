import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {REMOVE_UPDATE_CATEGORY} from "../../redux/actionTypes/actionsTypes";
import {updateCategory} from "../../redux/actions/categoriesActions";

const UpdateCategory = () => {

    const dispatch = useDispatch();

    const { update_category, btn_loading} = useSelector(state => state.categories);

    const closeModal = () =>{
        dispatch({
            type: REMOVE_UPDATE_CATEGORY
        })
    };

    const onSubmit = (event, data) =>{
        dispatch(updateCategory(update_category?.id, data, closeModal))
    };

    let condition = false;
    if(update_category !== null)
        condition = true;

    return (
        <div>

            <Modal isOpen={condition} toggle={closeModal}>
                <ModalHeader className="bg-warning text-white fw-bold px-4" toggle={closeModal}>Kategoriyani tahrirlash</ModalHeader>
                <ModalBody className="p-4">
                    <AvForm className="w-100" model={update_category} onValidSubmit={onSubmit}>

                        <AvField
                            type="text"
                            label="Kategoriya nomi"
                            name="name"
                            className="form-control mb-2"
                            required
                            validate={{
                                required: {value: true, errorMessage: 'Kategoriya nomini kiriting !'}
                            }}
                        />

                        <div className="d-flex mt-4">
                            <button id="quit-btn" onClick={closeModal} type="button"
                                    className="w-50 btn btn-light fw-bold">Chiqish
                            </button>
                            <button id="submit-btn" type="submit" className={`w-50 ms-2 fw-bold text-white btn-dark btn`}
                                    disabled={btn_loading}>Ma'lumotlarni saqlash
                            </button>
                        </div>


                    </AvForm>
                </ModalBody>
            </Modal>

        </div>
    );
};

export default UpdateCategory;