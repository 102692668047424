export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_OUT = "LOGIN_OUT";

export const BTN_LOADING_TRUE = "BTN_LOADING_TRUE";
export const BTN_LOADING_FALSE = "BTN_LOADING_FALSE";
export const LOADING_TRUE = "LOADING_TRUE";
export const LOADING_FALSE = "LOADING_FALSE";

export const GET_CATEGORY_AND_PRODUCT_DATA = "GET_CATEGORY_AND_PRODUCT_DATA";
export const GET_TRADE = "GET_TRADE";
export const GET_TRADES = "GET_TRADES";
export const SET_DELETE_TRADE = "SET_DELETE_TRADE";
export const REMOVE_DELETE_TRADE = "REMOVE_DELETE_TRADE";
export const SET_UPDATE_TRADE = "SET_UPDATE_TRADE";
export const REMOVE_UPDATE_TRADE = "REMOVE_UPDATE_TRADE";

export const SET_DELETE_TRADE_PAYMENT = "SET_DELETE_TRADE_PAYMENT";
export const REMOVE_DELETE_TRADE_PAYMENT = "REMOVE_DELETE_TRADE_PAYMENT";
export const SET_UPDATE_TRADE_PAYMENT = "SET_UPDATE_TRADE_PAYMENT";
export const REMOVE_UPDATE_TRADE_PAYMENT = "REMOVE_UPDATE_TRADE_PAYMENT";

export const GET_COURSES = "GET_COURSES";
export const SET_DELETE_COURSE = "SET_DELETE_COURSE";
export const REMOVE_DELETE_COURSE = "REMOVE_DELETE_COURSE";
export const SET_UPDATE_COURSE = "SET_UPDATE_COURSE";
export const REMOVE_UPDATE_COURSE = "REMOVE_UPDATE_COURSE";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const SET_DELETE_CATEGORY = "SET_DELETE_CATEGORY";
export const REMOVE_DELETE_CATEGORY = "REMOVE_DELETE_CATEGORY";
export const SET_UPDATE_CATEGORY = "SET_UPDATE_CATEGORY";
export const REMOVE_UPDATE_CATEGORY = "REMOVE_UPDATE_CATEGORY";

export const GET_CATEGORIES_DATA = "GET_CATEGORIES_DATA";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const SET_DELETE_PRODUCT = "SET_DELETE_PRODUCT";
export const REMOVE_DELETE_PRODUCT = "REMOVE_DELETE_PRODUCT";
export const SET_UPDATE_PRODUCT = "SET_UPDATE_PRODUCT";
export const REMOVE_UPDATE_PRODUCT = "REMOVE_UPDATE_PRODUCT";

export const GET_COURSE_STATISTIC = "GET_COURSE_STATISTIC";
export const GET_TEACHER_STATISTIC = "GET_TEACHER_STATISTIC";
export const GET_GROUP_STATISTIC = "GET_GROUP_STATISTIC";

export const GET_COURSE_AND_TEACHER_DATA = "GET_COURSE_AND_TEACHER_DATA";

