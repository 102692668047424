import React from 'react';
import "./table-loading.scss";
import ReactLoading from 'react-loading';

const TableLoading = () => {
    return (
        <div className="Table-loading d-flex align-items-center justify-content-center">
            <ReactLoading type="bars" color="#212529" height="200px" width="200px" />
        </div>
    );
};

export default TableLoading;