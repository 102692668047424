import React, {useState} from 'react';
import "./navbar.scss";
import {NavLink, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {LogOut} from "../../redux/actions/authActions";
import {FaUser} from "react-icons/fa";
import {CiLogout} from "react-icons/ci";
import {BiSolidUserRectangle} from 'react-icons/bi';
import {TbLogout2} from "react-icons/tb";
import {BsPersonGear} from "react-icons/bs";
import Logo from "../../assets/images/logo.jpg";

const Navbar = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [show_menu, setShowMenu] = useState(false);

    const {user, loading, btn_loading} = useSelector(state => state.auth);

    const [isSetting, setIsSetting] = useState(false);

    const openSetting = () => {
        setIsSetting(!isSetting)
    };

    const LogOutFunc = () => {
        history.push("/");
        dispatch(LogOut());
    };

    return (
        <div className="Navbar">
            <div className="Desktop-navbar py-2 border-2 border-dark">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between">

                        <NavLink to={'/'}>
                            <img src={Logo} width="150px" alt=""/>
                        </NavLink>

                        <ul className="menu">

                            <>
                                <li className="menu-item">
                                    <NavLink to="/admin/trades">Savdolar</NavLink>
                                </li>
                                <li className="menu-item">
                                    <NavLink to="/admin/category">Kategoriya</NavLink>
                                </li>
                                <li className="menu-item">
                                    <NavLink to="/admin/products">Mahsulotlar</NavLink>
                                </li>
                            </>

                        </ul>


                        <h6>
                            <div className="login-user">
                                <div onClick={openSetting} className="login-user-logo">
                                    <FaUser/>
                                </div>
                                <div onClick={openSetting}
                                     className={isSetting ? "login-user-content active" : "login-user-content"}>
                                    <div className='login-user-link'><BsPersonGear/> {user?.role}
                                    </div>
                                    <div className='login-user-link'><BiSolidUserRectangle/> {user?.username}
                                    </div>
                                    <div onClick={() => LogOutFunc()} className="login-user-link">
                                        <CiLogout/> Chiqish
                                    </div>
                                </div>
                            </div>
                        </h6>


                    </div>
                </div>
            </div>

            <div className="Mobile-navbar">
                <div className="container">
                    <div className="d-flex align-items-center justify-content-between">
                        <NavLink to={'/'}>
                            <img src={Logo} width="150px" alt=""/>
                        </NavLink>

                        <div className="d-flex align-items-center">
                            {
                                user ?

                                    <div className="login-user">
                                        <div onClick={openSetting} className="login-user-logo">
                                            <FaUser/>
                                        </div>
                                        <div onClick={openSetting}
                                             className={isSetting ? "login-user-content active" : "login-user-content"}>
                                            <div className='login-user-link'><BsPersonGear/> {user?.role}
                                            </div>
                                            <div className='login-user-link'>
                                                <BiSolidUserRectangle/> {user?.username}</div>
                                            <div onClick={() => LogOutFunc()} className="login-user-link">
                                                <CiLogout/> Chiqish
                                            </div>
                                        </div>
                                    </div>
                                    :

                                    ""

                            }
                            <div className={show_menu ? "menu-btn open" : "menu-btn"}
                                 onClick={() => setShowMenu(!show_menu)}>
                                <div className="menu-btn__burger"></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className={`mobile-menu ${!show_menu ? "close-menu" : "show-menu"}`}>

                <ul className="menu">

                    <>
                        <li className="menu-item">
                            <NavLink to="/admin/trades">Savdolar</NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to="/admin/category">Kategoriya</NavLink>
                        </li>
                        <li className="menu-item">
                            <NavLink to="/admin/products">Mahsulotlar</NavLink>
                        </li>
                        <li className="menu-item">
                            <a className="d-flex align-items-center" onClick={() => LogOutFunc()} href="#">
                                <TbLogout2 className="me-1"/> Chiqish
                            </a>
                        </li>
                    </>

                </ul>

            </div>

        </div>
    );
};

export default Navbar;