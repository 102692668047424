import {
    GET_COURSE_STATISTIC,
    LOADING_TRUE,
    LOADING_FALSE,
    GET_TEACHER_STATISTIC,
    GET_GROUP_STATISTIC, GET_COURSE_AND_TEACHER_DATA
} from "../actionTypes/actionsTypes";

const initialState = {
    course_statistic: [],
    teacher_statistic: [],
    group_statistic: [],
    courses: [],
    teachers: [],
    loading: false,
    btn_loading: false,
};


export const statisticReducer = (state = initialState, action) => {

    const {type, payload} = action;

    switch (type) {

        case LOADING_TRUE:

            return {
                ...state,
                loading: true
            };

        case LOADING_FALSE:

            return {
                ...state,
                loading: false
            };

        case GET_COURSE_STATISTIC:

            return {
                ...state,
                course_statistic: payload,
                loading: false
            };

        case GET_TEACHER_STATISTIC:

            return {
                ...state,
                teacher_statistic: payload,
                loading: false
            };

        case GET_GROUP_STATISTIC:

            return {
                ...state,
                group_statistic: payload,
                loading: false
            };

        case GET_COURSE_AND_TEACHER_DATA:

            return {
                ...state,
                courses: payload.courses,
                teachers: payload.teachers,
                loading: false
            };

        default:
            return state;

    }

};
