import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {REMOVE_UPDATE_TRADE} from "../../redux/actionTypes/actionsTypes";
import {updateTrade} from "../../redux/actions/tradesActions";

const UpdateTrade = ({getTradesRequest}) => {

    const dispatch = useDispatch();

    const {update_trade, categories, products, btn_loading} = useSelector(state => state.trades);
    const {user} = useSelector(state => state.auth);

    const closeModal = () => {
        dispatch({
            type: REMOVE_UPDATE_TRADE
        })
    };

    const onSubmit = (event, data) => {

        const birOyKeyingiSana = new Date(data.start);
        birOyKeyingiSana.setMonth(birOyKeyingiSana.getMonth() + 1);

        const year = birOyKeyingiSana.getFullYear();
        const month = (birOyKeyingiSana.getMonth() + 1).toString().padStart(2, '0');
        const day = birOyKeyingiSana.getDate().toString().padStart(2, '0');

        let formattedDate = '';

        if(update_trade?.payments?.length > 0){
            formattedDate = update_trade?.payments[0]?.finish;
        } else{
            formattedDate = `${year}-${month}-${day}`;
        }

        const myData = {
            ...data,
            product: data.product?.id,
            next_pay: formattedDate
        };

        dispatch(updateTrade(update_trade?.id, myData, closeModal, getTradesRequest));

    };

    let condition = false;
    if (update_trade !== null)
        condition = true;


    return (
        <div>

            <Modal isOpen={condition} toggle={closeModal}>
                <ModalHeader className="bg-warning text-white fw-bold px-4" toggle={closeModal}>Savdoni
                    tahrirlash</ModalHeader>
                <ModalBody className="p-4">
                    <AvForm className="w-100" model={update_trade} onValidSubmit={onSubmit}>

                        <AvField
                            type="text"
                            label="Mijoz nomi"
                            name="client_name"
                            className="form-control mb-2"
                            required
                            validate={{
                                required: {value: true, errorMessage: 'Mijoz nomini kiriting !'}
                            }}
                        />

                        <AvField
                            type="text"
                            label="Telefon raqami"
                            name="client_num"
                            className="form-control mb-2"
                            required
                            validate={{
                                required: {value: true, errorMessage: 'Telefon raqamini kiriting !'},
                            }}
                        />

                        <AvField
                            type="text"
                            label="Mijoz passport seriyasi va raqami"
                            name="client_passport"
                            className="form-control mb-2"
                            validate={{
                                maxLength: {value: 9, errorMessage: "9 ta belgidan iborat bo'lishi kerak !"},
                                minLength: {value: 9, errorMessage: "9 ta belgidan iborat bo'lishi kerak !"}
                            }}
                        />

                        <AvField
                            type="select"
                            name="product"
                            className="form-select mb-2"
                            label="Mahsulot"
                            validate={{
                                required: {value: true, errorMessage: 'Mahsulotni tanlang !'}
                            }}
                        >
                            <option value="" selected={true} disabled={true}>Mahsulotni tanlang
                            </option>
                            {
                                products?.length !== 0 ? products?.map((product, index) => (
                                        <option key={index}
                                                value={product.id}>{product.name}</option>
                                    )) :

                                    <option value="" disabled={true}>Mahsulot
                                        mavjud emas
                                        !</option>
                            }
                        </AvField>

                        <AvField
                            type="text"
                            label="Telefon IMEI"
                            name="imeika"
                            className="form-control mb-2"
                            required
                            validate={{
                                required: {value: true, errorMessage: 'Telefon IMEI kodini kiriting !'},
                            }}
                        />

                        <AvField
                            type="select"
                            name="type_pay"
                            className="form-select mb-2"
                            label="To'lov turi"
                            validate={{
                                required: {value: true, errorMessage: "To'lov turini tanlang !"}
                            }}
                        >
                            <option value="" selected={true} disabled={true}>To'lov turini tanlang</option>
                            <option value="Dollar">Dollar</option>
                            <option value="So'm">So'm</option>
                        </AvField>

                        <AvField
                            type="number"
                            label="Narxi"
                            name="price"
                            className="form-control mb-2"
                            required
                            validate={{
                                required: {value: true, errorMessage: 'Narxini kiriting !'}
                            }}
                        />

                        <AvField
                            type="number"
                            label="Oldindan to'lov"
                            name="ad_payment"
                            className="form-control mb-2"
                            required
                            validate={{
                                required: {value: true, errorMessage: "Oldindan to'lovni kiriting !"}
                            }}
                        />

                        <AvField
                            type="number"
                            label="Qo'shimcha to'lov (Ustama)"
                            name="surcharge"
                            className="form-control mb-2"
                            required
                            validate={{
                                required: {value: true, errorMessage: "Qo'shimcha to'lovni kiriting !"}
                            }}
                        />

                        <AvField
                            type="date"
                            label="Boshlanish sanasi"
                            name="start"
                            className="form-control mb-2"
                            required
                            validate={{
                                required: {value: true, errorMessage: 'Boshlanish sanasini kiriting !'}
                            }}
                        />

                        <AvField
                            type="number"
                            label="Muddat (oy)"
                            name="dedline"
                            className="form-control mb-2"
                            required
                            validate={{
                                required: {value: true, errorMessage: "Muddatni kiriting !"}
                            }}
                        />

                        <AvField
                            type="textarea"
                            label="Izoh"
                            name="desc"
                            className="form-control mb-2"
                        />

                        <div className="d-flex mt-4">
                            <button id="quit-btn" onClick={closeModal} type="button"
                                    className="w-50 btn btn-light fw-bold">Chiqish
                            </button>
                            <button id="submit-btn" type="submit"
                                    className={`w-50 ms-2 fw-bold text-white btn-dark btn`}
                                    disabled={btn_loading}>Ma'lumotlarni saqlash
                            </button>
                        </div>


                    </AvForm>
                </ModalBody>
            </Modal>

        </div>
    );
};

export default UpdateTrade;