import React from 'react';
import {FiWifiOff} from "react-icons/fi";

const NoInternet = () => {
    return (
        <>
            <div className="No-Internet min-vh-100 mb-5 mt-5">
                <div className="container mt-5">
                    <div className="row mt-5">
                        <div className="col-md-12 mt-5">
                            <div className="d-flex align-items-center justify-content-center flex-column mt-5">
                                <FiWifiOff style={{fontSize: "100px"}}/>
                                <h1 className="text-center mt-2">
                                    Siz offlayn rejimdasiz. Internetingizni tekshirib, qaytadan urinib ko'ring !!!
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NoInternet;