import axios from "axios";
import {PATH_NAME, TOKEN_NAME, USER} from "../../tools/constant";
import {
    BTN_LOADING_FALSE,
    BTN_LOADING_TRUE,
    LOGIN_FAIL, LOGIN_OUT,
    LOGIN_SUCCESS
} from "../actionTypes/actionsTypes";
import {toast} from "react-toastify";

export const AuthLogin = (userData, history) => async (dispatch) => {

    dispatch({
       type: BTN_LOADING_TRUE
    });

    try{

        const res = await axios.post(`${PATH_NAME}/Login/`, userData);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: {
                accessToken: res.data.access,
                user: res.data
            }
        });

        dispatch({
            type: BTN_LOADING_FALSE
        });

        localStorage.setItem(TOKEN_NAME, JSON.stringify(res.data.access));
        localStorage.setItem(USER, JSON.stringify(res.data));

        history.push("/admin/trades");


    } catch (e) {

        toast.error("Login yoki parol xato !!!");

        dispatch({
            type: BTN_LOADING_FALSE
        });

        dispatch({
            type: LOGIN_FAIL,
            payload: {
                error: e.message
            }
        });

    }

};

export const LogOut = () => async (dispatch) => {

    try{

        dispatch({
            type: LOGIN_OUT
        });

        localStorage.removeItem(TOKEN_NAME);
        localStorage.removeItem(USER);

    } catch (e) {

    }

};