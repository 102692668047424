import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {BiEdit} from "react-icons/bi";
import {RiDeleteBin2Line} from "react-icons/ri";
import {AiOutlinePlus} from "react-icons/ai";
import Layout from "../../components/layout/Layout";
import TableNoData from "../../components/table-no-data/TableNoData";
import TableLoading from "../../components/table-loading/TableLoading";
import {getCategoriesData, getProducts, setDeleteProduct, setUpdateProduct} from "../../redux/actions/productsActions";
import AddProduct from "../../components/admin/AddProduct";
import DeleteProduct from "../../components/admin/DeleteProduct";
import UpdateProduct from "../../components/admin/UpdateProduct";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {ImSearch} from 'react-icons/im';

const AdminProducts = () => {

    const dispatch = useDispatch();

    const {products, categories, loading} = useSelector(state => state.products);

    const [search, setSearch] = useState('');
    const [searchCategory, setSearchCategory] = useState('');

    const [search_condition, setSearchCondition] = useState(false);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const toggle = () => setShow(!show);

    const handleSubmit = (event, data) => {
        setSearch(data.search);
        setSearchCategory(data.category);
        setSearchCondition(!search_condition);
    };

    useEffect(() => {
        dispatch(getProducts(search, searchCategory));
    }, [search_condition]);

    useEffect(() => {
        dispatch(getCategoriesData());
    }, []);

    const setDeleteProductFunc =(product)=>{
        dispatch(setDeleteProduct(product));
    };

    const setUpdateProductFunc =(product)=>{
        dispatch(setUpdateProduct(product));
    };

    const getProductsRequest =()=>{
        dispatch(getProducts(search, searchCategory));
    };

    return (
        <Layout>
            <div className="Teachers min-vh-100 mb-5 mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex align-items-center justify-content-between">
                                <h2 className="text-dark fw-bold text-center">Mahsulotlar</h2>
                                <button onClick={()=>handleShow()} className="btn btn-success fw-bold">
                                    MAHSULOT
                                    <AiOutlinePlus className="ms-2"/>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-12">

                            <AvForm className="my-5" onValidSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-3">
                                        <AvField
                                            value={searchCategory}
                                            type="select"
                                            name="category"
                                            className="form-select mb-2 mb-sm-0"
                                        >
                                            <option value="" selected={true}>Kategoriyani tanlang
                                            </option>
                                            {
                                                categories?.length !== 0 ? categories?.map((category, index) => (
                                                        <option key={index}
                                                                value={category.id}>{category.name}</option>
                                                    )) :

                                                    <option value="" disabled={true}>Kategoriya
                                                        mavjud emas
                                                        !</option>
                                            }
                                        </AvField>
                                    </div>

                                    <div className="col-md-3">
                                        <AvField
                                            value={search}
                                            type="text"
                                            name="search"
                                            placeholder="Search..."
                                            className="form-control mb-2 mb-sm-0"
                                        />
                                    </div>

                                    <div className="col-md-2">
                                        <button className="btn btn-dark w-100">QIDIRISH <ImSearch className="ms-2"/>
                                        </button>
                                    </div>
                                </div>
                            </AvForm>

                        </div>

                        <div className="col-md-12">
                            <div className="table-responsive">
                                <table className="table table-bordered table-hover text-center">
                                    <thead>
                                    <tr>
                                        <th className="col-1">№</th>
                                        <th className="">Kategoriya</th>
                                        <th className="">Mahsulot nomi</th>
                                        <th className="">Izoh</th>
                                        <th className="col-2">Sozlamalar</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        loading ?
                                            <tr>
                                                <td colSpan={10}>
                                                    <TableLoading/>
                                                </td>
                                            </tr> :

                                            products?.length > 0 ? products?.map((product, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            {index + 1}
                                                        </td>
                                                        <td>{product?.category?.name}</td>
                                                        <td>{product.name}</td>
                                                        <td>{product.desc}</td>
                                                        <td className="justify-content-around d-flex align-items-center">
                                                            <button onClick={()=>setUpdateProductFunc(product)} className="btn btn-warning btn-sm">
                                                                <BiEdit/>
                                                            </button>
                                                            <button onClick={()=>setDeleteProductFunc(product)} className="btn btn-danger btn-sm">
                                                                <RiDeleteBin2Line/>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )) :

                                                <tr>
                                                    <th colSpan={10}>
                                                        <TableNoData text="Mahsulot topilmadi !!!"/>
                                                    </th>
                                                </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <AddProduct getProductsRequest={getProductsRequest} handleClose={handleClose} show={show} toggle={toggle}/>

            <DeleteProduct getProductsRequest={getProductsRequest}/>

            <UpdateProduct getProductsRequest={getProductsRequest}/>

        </Layout>
    );
};

export default AdminProducts;