import React, {useState} from 'react';
import Layout from "../../components/layout/Layout";
import {Nav, NavItem, NavLink, TabContent, TabPane} from 'reactstrap';
import CourseStatistic from "../../components/statistic/CourseStatistic";
import TeacherStatistic from "../../components/statistic/TeacherStatistic";
import "./statistic.scss";
import {AiOutlineAreaChart, AiOutlineBarChart} from "react-icons/ai";
import GroupStatistic from "../../components/statistic/GroupStatistic";

const Statistic = () => {

    const [activeTabPage, setActiveTabPage] = useState('1');

    return (
        <Layout>
            <div className="Statistic min-vh-100 mb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className="text-dark fw-bold text-center mt-5">
                                <AiOutlineAreaChart/> Statistika <AiOutlineBarChart/></h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mt-4">
                            <div className="page-tab-box">
                                <Nav tabs className="page-nav-tabs mb-4">
                                    <NavItem>
                                        <NavLink className={activeTabPage == '1' ? 'active' : ''}
                                                 onClick={() => setActiveTabPage('1')}>
                                            Kurslar
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={activeTabPage == '2' ? 'active' : ''}
                                                 onClick={() => setActiveTabPage('2')}>
                                            Guruhlar
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink className={activeTabPage == '3' ? 'active' : ''}
                                                 onClick={() => setActiveTabPage('3')}>
                                            Ustozlar
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent activeTab={activeTabPage}>
                                    <TabPane tabId="1">
                                        <CourseStatistic/>
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <GroupStatistic/>
                                    </TabPane>
                                    <TabPane tabId="3">
                                        <TeacherStatistic/>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default Statistic;