import {
    BTN_LOADING_FALSE,
    BTN_LOADING_TRUE,
    GET_CATEGORY_AND_PRODUCT_DATA, GET_TRADE,
    GET_TRADES,
    LOADING_FALSE,
    LOADING_TRUE,
    SET_DELETE_TRADE, SET_DELETE_TRADE_PAYMENT,
    SET_UPDATE_TRADE, SET_UPDATE_TRADE_PAYMENT
} from "../actionTypes/actionsTypes";
import axios from "axios";
import {toast} from "react-toastify";
import {PATH_NAME, TOKEN_NAME} from "../../tools/constant";

export const getTrade = (id) => async (dispatch) => {

    dispatch({
        type: LOADING_TRUE
    });

    try {

        const res = await axios.get(PATH_NAME + `/api/trade/${id}/`);

        dispatch({
            type: GET_TRADE,
            payload: {
                trade: res.data,
                tradePayments: res.data.payments
            }
        });

        dispatch({
            type: LOADING_FALSE,
        });

    } catch (err) {
        dispatch({
            type: LOADING_FALSE,
        });
    }

};

export const getTrades = (searchProduct, searchName, searchStatus, searchDate, searchIMEI, page) => async (dispatch) => {

    dispatch({
        type: LOADING_TRUE
    });

    try {

        const res = await axios.get(PATH_NAME + `/api/trade/?page=${page}&product=${searchProduct}&client_name=${searchName}&status=${searchStatus}&next_pay=${searchDate}&search=${searchIMEI}`);

        const arr = res.data.results;

        dispatch({
            type: GET_TRADES,
            payload: {
                trades: arr,
                numberOfPages: res?.data?.total_pages
            }
        });

        dispatch({
            type: LOADING_FALSE,
        });

    } catch (err) {
        dispatch({
            type: LOADING_FALSE,
        });
    }

};

export const getCategoryAndProductData = () => async (dispatch) => {

    try {

        const res1 = await axios.get(PATH_NAME + `/api/category/`);
        const res2 = await axios.get(PATH_NAME + `/api/product/`);

        dispatch({
            type: GET_CATEGORY_AND_PRODUCT_DATA,
            payload: {
                categories: res1.data,
                products: res2.data,
            }
        });

    } catch (err) {

    }

};

export const addTrade = (data, handleClose, getTradesRequest) => async (dispatch) => {

    dispatch({
        type: BTN_LOADING_TRUE
    });

    try {

        const accessToken = JSON.parse(localStorage.getItem(TOKEN_NAME));

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const res = await axios.post(`${PATH_NAME}/api/trade/`, data, config);

        handleClose();

        getTradesRequest();

        toast.success(`Savdo muvaffaqiyatli yaratildi !!!`);

    } catch (err) {

        dispatch({
            type: BTN_LOADING_FALSE,
        });

        toast.error(`Ma'lumot yaratilmadi !!!`);
    } finally {
        dispatch({
            type: BTN_LOADING_FALSE,
        });
    }

};

export const addTradePayment = (tradeID, data, handleClose) => async (dispatch) => {

    dispatch({
        type: BTN_LOADING_TRUE
    });

    try {

        const accessToken = JSON.parse(localStorage.getItem(TOKEN_NAME));

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const res = await axios.post(`${PATH_NAME}/api/payments/`, data, config);

        handleClose();

        dispatch(getTrade(tradeID));

        toast.success(`To'lov muvaffaqiyatli yaratildi !!!`);

    } catch (err) {

        dispatch({
            type: BTN_LOADING_FALSE,
        });

        toast.error(`Ma'lumot yaratilmadi !!!`);
    } finally {
        dispatch({
            type: BTN_LOADING_FALSE,
        });
    }

};

export const setDeleteTrade = (trade) => async (dispatch) => {

    try {

        dispatch({
            type: SET_DELETE_TRADE,
            payload: trade
        });

    } catch (err) {
        console.log(err)
    }
};

export const setDeleteTradePayment = (payment) => async (dispatch) => {

    try {

        dispatch({
            type: SET_DELETE_TRADE_PAYMENT,
            payload: payment
        });

    } catch (err) {
        console.log(err)
    }
};

export const setUpdateTrade = (trade) => async (dispatch) => {

    try {

        dispatch({
            type: SET_UPDATE_TRADE,
            payload: trade
        });

    } catch (err) {
        console.log(err)
    }
};

export const setUpdateTradePayment = (payment) => async (dispatch) => {

    try {

        dispatch({
            type: SET_UPDATE_TRADE_PAYMENT,
            payload: payment
        });

    } catch (err) {
        console.log(err)
    }
};

export const deleteTrade = (tradeID, handleClose, getTradesRequest) => async (dispatch) => {

    dispatch({
        type: BTN_LOADING_TRUE
    });

    try {

        const accessToken = JSON.parse(localStorage.getItem(TOKEN_NAME));

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const res = await axios.delete(`${PATH_NAME}/api/trade/${tradeID}/`, config);

        handleClose();

        toast.success(`Savdo muvaffaqiyatli o'chirildi !!!`);

        getTradesRequest();

    } catch (err) {

        dispatch({
            type: BTN_LOADING_FALSE,
        });

        toast.error(`Ma'lumot o'chirilmadi !!!`);
    } finally {
        dispatch({
            type: BTN_LOADING_FALSE,
        });
    }

};

export const deleteTradePayment = (tradeID, paymentID, handleClose) => async (dispatch) => {

    dispatch({
        type: BTN_LOADING_TRUE
    });

    try {

        const accessToken = JSON.parse(localStorage.getItem(TOKEN_NAME));

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const res = await axios.delete(`${PATH_NAME}/api/payments/${paymentID}/`, config);

        handleClose();

        dispatch(getTrade(tradeID));

        toast.success(`To'lov muvaffaqiyatli o'chirildi !!!`);

    } catch (err) {

        dispatch({
            type: BTN_LOADING_FALSE,
        });

        toast.error(`Ma'lumot o'chirilmadi !!!`);
    } finally {
        dispatch({
            type: BTN_LOADING_FALSE,
        });
    }

};

export const updateTrade = (tradeID, data, handleClose, getTradesRequest) => async (dispatch) => {

    dispatch({
        type: BTN_LOADING_TRUE
    });

    try {

        const accessToken = JSON.parse(localStorage.getItem(TOKEN_NAME));

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const res = await axios.patch(`${PATH_NAME}/api/trade/${tradeID}/`, data, config);

        handleClose();

        toast.success(`Savdo muvaffaqiyatli tahrirlandi !!!`);

        getTradesRequest();

    } catch (err) {

        dispatch({
            type: BTN_LOADING_FALSE,
        });

        toast.error(`Ma'lumot o'zgartirilmadi !!!`);
    } finally {
        dispatch({
            type: BTN_LOADING_FALSE,
        });
    }

};

export const updateTradeForNextPay = (tradeID, data) => async (dispatch) => {
    try {

        const accessToken = JSON.parse(localStorage.getItem(TOKEN_NAME));

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const res = await axios.patch(`${PATH_NAME}/api/trade/${tradeID}/`, data, config);

    } catch (err) {

    }
};

export const updateTradePayment = (tradeID, paymentID, data, handleClose) => async (dispatch) => {

    dispatch({
        type: BTN_LOADING_TRUE
    });

    try {

        const accessToken = JSON.parse(localStorage.getItem(TOKEN_NAME));

        let config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        };

        const res = await axios.patch(`${PATH_NAME}/api/payments/${paymentID}/`, data, config);

        handleClose();

        toast.success(`To'lov muvaffaqiyatli tahrirlandi !!!`);

        dispatch(getTrade(tradeID));

    } catch (err) {

        dispatch({
            type: BTN_LOADING_FALSE,
        });

        toast.error(`Ma'lumot o'zgartirilmadi !!!`);
    } finally {
        dispatch({
            type: BTN_LOADING_FALSE,
        });
    }

};
