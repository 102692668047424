import React from 'react';
import Layout from "../../components/layout/Layout";
import {TbError404} from "react-icons/tb";

const NotFound = () => {
    return (
        <Layout>
            <div className="Not-found min-vh-100 mb-5 mt-5">
                <div className="container mt-5">
                    <div className="row mt-5">
                        <div className="col-md-12 mt-5">
                            <div className="d-flex align-items-center justify-content-center flex-column mt-5">
                                <TbError404 style={{fontSize: "100px"}}/>
                                <h1 className="text-center mt-2">
                                    Sahifa topilmadi !!!
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default NotFound;