import React, {useEffect, useState} from "react";
import Login from "./pages/login/Login";
import NotFound from "./pages/not-found/NotFound";
import {Redirect, Route, Switch} from "react-router-dom";
import PrivateRoute from "./routes/PrivateRoute";
import {ToastContainer} from "react-toastify";
import AdminCategories from "./pages/admin/AdminCategories";
import AdminProducts from "./pages/admin/AdminProducts";
import AdminTrades from "./pages/admin/AdminTrades";
import SiteLoading from "./components/site-loading/SiteLoading";
import Statistic from "./pages/statistic/Statistic";
import AdminTrade from "./pages/admin/AdminTrade";

function App() {

    const [site_loading, setSiteLoading] = useState(true);

    useEffect(() => {

        setTimeout(() => {
            setSiteLoading(false);
        }, 1000);

    }, []);

    if (site_loading) {
        return (
            <SiteLoading/>
        )
    }

    return (
        <>
            <div className="App">
                <Switch>

                    <Route exact path="/statistic" component={Statistic}/>
                    <Route exact path="/login" component={Login}/>
                    <Route exact path="/404" component={NotFound}/>

                    <PrivateRoute exact path="/admin/category" forUser="DIRECTOR" component={AdminCategories}/>
                    <PrivateRoute exact path="/admin/products" forUser="DIRECTOR" component={AdminProducts}/>
                    <PrivateRoute exact path="/admin/trades" forUser="DIRECTOR" component={AdminTrades}/>
                    <PrivateRoute exact path="/admin/trades/:id" forUser="DIRECTOR" component={AdminTrade}/>

                    <Route exact path="/">
                        <Redirect to="/admin/trades"/>
                    </Route>

                    <Route exact path="*">
                        <Redirect to="/404"/>
                    </Route>

                </Switch>
            </div>

            <ToastContainer autoClose={2000}/>
        </>

    );
}

export default App;