import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {REMOVE_UPDATE_PRODUCT} from "../../redux/actionTypes/actionsTypes";
import {updateProduct} from "../../redux/actions/productsActions";

const UpdateProduct = ({getProductsRequest}) => {

    const dispatch = useDispatch();

    const { update_product, categories, btn_loading} = useSelector(state => state.products);

    const closeModal = () =>{
        dispatch({
            type: REMOVE_UPDATE_PRODUCT
        })
    };

    const onSubmit = (event, data) =>{

        data = {
            ...data,
            category: data?.category?.id
        };

        dispatch(updateProduct(update_product?.id, data, closeModal, getProductsRequest))
    };

    let condition = false;
    if(update_product !== null)
        condition = true;

    return (
        <div>

            <Modal isOpen={condition} toggle={closeModal}>
                <ModalHeader className="bg-warning text-white fw-bold px-4" toggle={closeModal}>Mahsulotni tahrirlash</ModalHeader>
                <ModalBody className="p-4">
                    <AvForm className="w-100" model={update_product} onValidSubmit={onSubmit}>

                        <AvField
                            type="text"
                            label="Mahsulot nomi"
                            name="name"
                            className="form-control mb-2"
                            required
                            validate={{
                                required: {value: true, errorMessage: 'Mahsulot nomini kiriting !'}
                            }}
                        />

                        <AvField
                            type="select"
                            name="category"
                            className="form-select mb-2"
                            label="Kategoriya"
                            validate={{
                                required: {value: true, errorMessage: 'Kategoriyani tanlang !'}
                            }}
                        >
                            <option value="" selected={true} disabled={true}>Kategoriyani tanlang
                            </option>
                            {
                                categories?.length !== 0 ? categories?.map((category, index) => (
                                        <option key={index}
                                                value={category}>{category.name}</option>
                                    )) :

                                    <option value="" disabled={true}>Kategoriya
                                        mavjud emas
                                        !</option>
                            }
                        </AvField>

                        <AvField
                            type="textarea"
                            label="Izoh"
                            name="desc"
                            className="form-control mb-2"
                        />

                        <div className="d-flex mt-4">
                            <button id="quit-btn" onClick={closeModal} type="button"
                                    className="w-50 btn btn-light fw-bold">Chiqish
                            </button>
                            <button id="submit-btn" type="submit" className={`w-50 ms-2 fw-bold text-white btn-dark btn`}
                                    disabled={btn_loading}>Ma'lumotlarni saqlash
                            </button>
                        </div>


                    </AvForm>
                </ModalBody>
            </Modal>

        </div>
    );
};

export default UpdateProduct;